import * as Endpoints from "api/endpoints";
import { useApiSWR } from "./api";
import uniq from "lodash-es/uniq";
import { useMemo, useContext } from "react";
import {
  FundRecommendation,
  InsuranceShell,
  ShellRecommendationPerformance,
} from "models/funds";
import { UserById } from "api/endpoints";
import { AuthContext } from "context/auth";

export const useInsuranceShells = () => {
  const { data, error, revalidate } = useApiSWR<InsuranceShell[]>(
    Endpoints.InsuranceShells,
    {
      revalidateOnMount: false,
    }
  );
  let filteredShells = data?.filter((shell) => {
    if (shell.show_to_user) {
      return shell;
    }
  });
  const sortedShells = filteredShells?.sort((a: any, b: any) => {
    if (a.pension_institute < b.pension_institute) {
      return -1;
    }
    if (a.pension_institute > b.pension_institute) {
      return 1;
    }
    return 0;
  });
  let ppmArray: InsuranceShell[] = [];
  let notPPMArray: InsuranceShell[] = [];

  sortedShells?.forEach((sh) => {
    if (sh.show_to_user)
      if (
        sh.pension_institute === "Pensionsmyndigheten" ||
        sh.pension_institute === "PPM Pensionsmyndigheten"
      ) {
        ppmArray.push({ ...sh, pension_institute: "PPM Pensionsmyndigheten" });
      } else {
        notPPMArray.push(sh);
      }
  });

  let resultShells: InsuranceShell[] = [...ppmArray, ...notPPMArray];
  return {
    isLoading: !data && !error,
    shells: resultShells,
    revalidate,
  };
};

export const useFollowedRecommendations = () => {
  const { userId } = useContext(AuthContext);
  const {
    data: followed_recommendations,
    error,
    revalidate,
  } = useApiSWR<any[]>(
    Endpoints.FollowedRecommendations(userId ? UserById(userId) : ""),
    {
      revalidateOnMount: false,
    }
  );
  // const sortedShells = data?.sort((a: any, b: any) => {
  //   if (a.pension_institute < b.pension_institute) {
  //     return -1;
  //   }
  //   if (a.pension_institute > b.pension_institute) {
  //     return 1;
  //   }
  //   return 0;
  // });
  // let ppmArray: InsuranceShell[] = [];
  // let notPPMArray: InsuranceShell[] = [];

  // sortedShells?.forEach((sh) => {
  //   if (
  //     sh.pension_institute === "Pensionsmyndigheten" ||
  //     sh.pension_institute === "PPM Pensionsmyndigheten"
  //   ) {
  //     ppmArray.push({ ...sh, pension_institute: "PPM Pensionsmyndigheten" });
  //   } else {
  //     notPPMArray.push(sh);
  //   }
  // });

  // let resultShells: InsuranceShell[] = [...ppmArray, ...notPPMArray];
  return {
    isLoading: !followed_recommendations && !error,
    followed_recommendations: followed_recommendations,
    revalidate,
  };
};

export const useInsuranceShellsShow = () => {
  const { data, error, revalidate } = useApiSWR<InsuranceShell[]>(
    Endpoints.InsuranceShells,
    {
      revalidateOnMount: false,
    }
  );
  let filteredShells = data?.filter((shell) => {
    if (shell.show_to_user) {
      return shell;
    }
  });
  const sortedShells = filteredShells?.sort((a: any, b: any) => {
    if (a.pension_institute < b.pension_institute) {
      return -1;
    }
    if (a.pension_institute > b.pension_institute) {
      return 1;
    }
    return 0;
  });
  let ppmArray: InsuranceShell[] = [];
  let notPPMArray: InsuranceShell[] = [];

  sortedShells?.forEach((sh) => {
    if (sh.pension_institute === "Pensionsmyndigheten") {
      ppmArray.push({ ...sh, pension_institute: "PPM Pensionsmyndigheten" });
    } else {
      notPPMArray.push(sh);
    }
  });

  let resultShells: InsuranceShell[] = [...ppmArray, ...notPPMArray];
  return {
    isLoading: !data && !error,
    shells: resultShells,
    revalidate,
  };
};

export const usePensionInstitutes = () => {
  const { isLoading, shells } = useInsuranceShellsShow();

  const pensionInstitutes = useMemo(
    () => uniq(shells?.map((sh) => sh.pension_institute) || []),

    [shells]
  );

  return {
    isLoading,
    pensionInstitutes,
  };
};

export const useInsuranceShellsByInstitute = (pensionInstitute: string) => {
  const { isLoading, shells: allShells } = useInsuranceShellsShow();

  const shells = useMemo(
    () =>
      allShells?.filter((sh) => {
        if (sh.pension_institute === pensionInstitute) {
          if (pensionInstitute === "Pensionsmyndigheten") {
          }
          return { ...sh, pension_institute: "PPM Pensionsmyndigheten" };
        }
      }) || [],

    [allShells, pensionInstitute]
  );

  return {
    isLoading,
    shells,
  };
};

export const useShellRecommendations = (shellId: string | undefined) => {
  const { data, error, revalidate } = useApiSWR<FundRecommendation[]>(
    shellId && Endpoints.ShellRecommendations(shellId)
  );

  return {
    isLoading: !data && !error,
    recommendations: data,
    revalidate,
  };
};

export const useShellRecommendationsPerfomrance = (
  shellId: string | undefined
) => {
  const { data, error, revalidate } = useApiSWR<ShellRecommendationPerformance>(
    shellId && Endpoints.ShellRecommendationPerformance(shellId)
  );

  return {
    isLoading: !data && !error,
    performance: data,
    revalidate,
  };
};
export const useAverageInShell = (shellId: string | undefined) => {
  const { data, error, revalidate } = useApiSWR<any>(
    shellId && Endpoints.InsuranceShellAverage(shellId)
  );

  return { isLoading: !data && !error, average: data, revalidate };
};
