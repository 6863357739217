export function trackHeapEvent(
  name: string,
  props: { [key: string]: string } = {}
) {
  const heap = (window as any).heap;
  if (heap && heap.track) {
    heap.track(name, props);
  }
}

export function identify(userId: string) {
  const heap = (window as any).heap;
  if (heap && heap.identify) {
    heap.identify(userId);
  }
}

export enum HeapEventNames {
  LoggedInUsingCode = "User logged in via code",
  LoggedInUsingStandardWay = "The user is logged in in the standard way",
}

export enum HeapPagesEvents {
  ShowSettingsPage = "ShowSettingsPage",
  ShowFavouritesPage = "ShowFavouritesPage",
  ShowAllPortfoliosPage = "ShowAllPortfoliosPage",
  ShowPensionInstitutesPage = "ShowPensionInstitutesPage",
  ShowSupportPage = "ShowSupportPage",
  ShowPortfolioInfoPage = "ShowPortfolioInfoPage",
  ShowShellRecommendationPage = "ShowShellRecommendationPage",
  ShowInsuranceShellsPage = "ShowInsuranceShellsPage",
  ShowHomePage = "ShowHomePage",
  ShowLoginPage = "ShowLoginPage",
  ShowNewsLettersPage = "ShowNewsLettersPage",
}

export enum HeapActionsEvents {
  UpdateUserInfo = "UpdateUserInfo",
  AddSinfulPortfolio = "AddSinfulPortfolio",
  AddSustainablePortfolio = "AddSustainablePortfolio",
}
