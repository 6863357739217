import textStyles from "../foundations/textStyles";

const Modal = {
  baseStyle: {
    header: {
      px: 6,
      py: 4,
      ...textStyles.h3,
    },
    dialog: {
      mx: [4],
    },
  },
};

export default Modal;
