import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useWindowHeight } from "hooks/layout";
import * as Icons from "assets/icons";
import { ClickableIcon } from "./ClickableIcon";

interface LayoutProps {
  title: string;
  children?: React.ReactNode;
  endIcon?: React.ReactElement;
  isEndIconDisabled?: boolean;

  onGoBack(): void;
  onEndIconClick?(): void;
}

export const Layout = ({
  title,
  children,
  endIcon,
  isEndIconDisabled,
  onGoBack,
  onEndIconClick,
}: LayoutProps) => {
  const windowHeight = useWindowHeight();

  return (
    <>
      <Box
        minHeight={windowHeight}
        px={[8]}
        paddingTop={[5, 8]}
        paddingBottom={[8, 12]}
        d="flex"
        flexDir="column"
        alignItems="stretch"
        mx="auto"
        maxWidth={["100%", "600px"]}
      >
        <Box
          position="relative"
          d="flex"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          mb={[10, 14]}
        >
          <Box
            position="absolute"
            left="0"
            top="50%"
            transform="translateY(-50%)"
          >
            <ClickableIcon
              marginLeft={[-4, -8]}
              aria-label="Arrow Back"
              icon={
                <Icons.ArrowLeft
                  width={["21px", "28px"]}
                  height={["16px", "21px"]}
                />
              }
              onClick={onGoBack}
            />
          </Box>

          <Text textStyle="h3" textAlign="center" as="h3">
            {title}
          </Text>

          {endIcon && (
            <Box
              position="absolute"
              right="0"
              top="50%"
              transform="translateY(-50%)"
            >
              <ClickableIcon
                aria-label="Arrow Back"
                icon={endIcon}
                onClick={onEndIconClick}
                disabled={isEndIconDisabled}
              />
            </Box>
          )}
        </Box>
        {children}
      </Box>
    </>
  );
};
