import React, { useMemo } from "react";

import { InsurancesRoute } from "lib/routes";

import { InsuranceType } from "models/axa-products";
import { useInsurancePurchaseData } from "hooks/pages/insurancePurchase";
import { LifeInsuranceConfig } from "components/insurance-purchase/life";
import { useHistory } from "react-router-dom";

const configs = {
  [InsuranceType.Life]: LifeInsuranceConfig,
  [InsuranceType.Safety]: LifeInsuranceConfig,
};

export const InsurancePurchase = () => {
  const history = useHistory();
  const {
    isSaving,
    isUpsell,
    insuranceType,
    insuranceOffer,
    price,
    productCode,
    selectedAmount,
    setSelectedAmount,
    questions,
    setQuestions,
    bankDetails,
    setBankDetails,
    purchaseAxaInsurance,
  } = useInsurancePurchaseData();

  const {
    BenefitsComponent,
    QuestionsComponent,
    BankDetailsComponent,
    SummaryComponent,
  } = useMemo(() => configs[insuranceType], [insuranceType]);

  if (!selectedAmount) {
    return (
      <BenefitsComponent
        alternatives={insuranceOffer?.options}
        onNext={setSelectedAmount}
        onPrev={() => history.push(InsurancesRoute)}
      />
    );
  } else if (!questions.completed) {
    return (
      <QuestionsComponent
        initAllChecked={questions.allAnswered}
        onPrev={() => setSelectedAmount(undefined)}
        onNext={() => setQuestions({ allAnswered: true, completed: true })}
      />
    );
  } else if (!bankDetails.completed && !isUpsell) {
    return (
      <BankDetailsComponent
        initalFormValues={bankDetails.form}
        price={price}
        onPrev={() => setQuestions({ allAnswered: true, completed: false })}
        onNext={(form) => setBankDetails({ form, completed: true })}
      />
    );
  } else if (
    selectedAmount &&
    questions.completed &&
    (bankDetails.completed || isUpsell)
  ) {
    return (
      <SummaryComponent
        isSaving={isSaving}
        price={price}
        coverage={selectedAmount || 0}
        onPrev={() =>
          isUpsell
            ? setQuestions({ allAnswered: true, completed: false })
            : setBankDetails({ ...bankDetails, completed: false })
        }
        onNext={() =>
          isUpsell
            ? null
            : purchaseAxaInsurance({
                product_code: productCode!,
                cover_amount: selectedAmount,
                gross_monthly_premium: price,
                autogiro: {
                  bank_name: bankDetails.form.bankName,
                  sort_code: bankDetails.form.sortCode,
                  account_number: bankDetails.form.accountNumber,
                },
              })
        }
      />
    );
  }

  return null;
};
