function apiUrl(path: string): string {
  if (!path) {
    throw new Error("Url is not defined");
  }

  if (path.startsWith("/")) {
    path = path.substring(1);
  }

  return `/api/${path}`;
}

export const WhoAmI = apiUrl("/whoami");
export const PersonalLink = (code: string) =>
  apiUrl(`/personal-links?code=${code}`);
export const Login = apiUrl("/login");
export const Logout = apiUrl("/logout");
export const LoginStatus = apiUrl("/login/status");
export const UserById = (id: string) => apiUrl(`/users/${id}`);
export const PPMConsentGiven = (userId: string) =>
  `${UserById(userId)}/ppm-consent-received`;

export const InsuranceShells = apiUrl("/insuranceshells?epoch=v2");
export const ShellRecommendations = (shellId: string) =>
  apiUrl(`insuranceshells/${shellId}/recommendations/latest`);
export const ShellRecommendationPerformance = (shellId: string) =>
  apiUrl(`insuranceshells/${shellId}/recommendations/performance`);

export const AxaInsurances = apiUrl("axagroup-insurances");
export const AxaInsurancesUpsell = `${AxaInsurances}/upsell`;
export const AxaProducts = (personNumber: string) =>
  `${AxaInsurances}/products/current?person-number=${personNumber}`;
export const FollowedRecommendations = (userId: string) =>
  apiUrl(`/${userId}/followed_recommendation`);
export const InsuranceShellAverage = (shellId: string) =>
  apiUrl(`/insuranceshells/${shellId}/averages-in-shell`);
