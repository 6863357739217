import React from "react";
import { Card } from "components/shared";
import { Image, Text } from "@chakra-ui/react";
import { FundsBg } from "assets/images";
import { useHistory } from "react-router-dom";
import { PensionInstitutesRoute } from "lib/routes";

export const FundsCard = () => {
  const history = useHistory();

  return (
    <Card
      cursor="pointer"
      height={["280px", "390px"]}
      position="relative"
      overflow="hidden"
      mb={["36px", "45px"]}
      onClick={() => history.push(PensionInstitutesRoute)}
    >
      <Text textStyle="h1" textAlign="center" mt={[10, 14]} as="h1">
        Välja fonder
      </Text>
      <Text textStyle="p" textAlign="center" width="70%" mt={[3, 5]} mx="auto">
        Klicka här för att se de olika fondportföljerna
      </Text>
      <Image
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        src={FundsBg}
        zIndex={-1}
        maxW="initial"
        width="110%"
        height="110%"
        userSelect="none"
      ></Image>
    </Card>
  );
};
