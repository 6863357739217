import Input from "./input";

function baseStyleField(props: Record<string, any>) {
  return {
    ...Input.baseStyle.field,
    appearance: "none",
    paddingBottom: "1px",
    lineHeight: "normal",
    "> option, > optgroup": {
      bg: "white",
    },
  };
}

const baseStyle = (props: Record<string, any>) => ({
  field: baseStyleField(props),
});

const select = {
  baseStyle,
  variants: Input.variants,
  defaultProps: Input.defaultProps,
};

export default select;
