export enum InsuranceType {
  Life = "life",
  Safety = "safety",
}

export enum InsurancePurchaseType {
  Initial = "initial",
  Upsell = "upsell",
}

export interface AxaProduct {
  id: string;
  description: string;
  cover_amount_unit: string;
  type: InsuranceType;
  options: AxaInsuranceOptions;
}

export interface AxaInsuranceOptions {
  forPersonOfAge: number;
  coverAmounts: number[];
  grossMonthlyPremium: { [key: number]: number };
}

export interface AxaInsuranceRequest {
  user: string;
  product_code: string;
  cover_amount: number;
  gross_monthly_premium: number;
  autogiro:
    | {
        bank_name: string;
        account_number: string;
        sort_code: string;
      }
    | {};
}

export interface AxaGroupInsurance {
  _id: string;
  gross_monthly_premium: number;
  cover_amount: number;
  product_code: string;
  user: string;
  insurance_number: string;
  start_date: string;
  end_date: string;
}
