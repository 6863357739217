import React, { PropsWithChildren } from "react";
import { useField } from "formik";
import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Box,
} from "@chakra-ui/react";

interface BaseFieldProps {
  disabled?: boolean;
  name: string;
}

interface FormikTextInputProps extends BaseFieldProps {
  type?: "number" | "text" | "email" | "tel";
  inputMode?: "numeric" | "text" | "email" | "tel";

  label?: string;
  placeholder?: string;
  autoComplete?: string;
}

export const FormikTextInput = ({
  type,
  inputMode,
  label,
  name,
  placeholder,
  disabled,
  autoComplete,
}: FormikTextInputProps) => {
  const [field, meta] = useField<string>(name);
  const error = meta.touched ? meta.error : undefined;

  return (
    <FormControl
      isInvalid={!!error}
      id={`formik-text-input-${name}`}
      isDisabled={disabled}
      mb={4}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        type={type}
        inputMode={inputMode}
        {...field}
        placeholder={placeholder}
        autoComplete={autoComplete}
        formNoValidate
      />
      <Box
        marginTop={2}
        transition="opacity 200ms ease-out"
        height="24px"
        color="red.500"
        fontSize={["sm", "md"]}
        fontFamily="main"
        letterSpacing={0}
        fontWeight={400}
        lineHeight="normal"
        opacity={error ? 1 : 0}
      >
        {error}
      </Box>
    </FormControl>
  );
};

interface FormikCheckboxProps extends BaseFieldProps {
  label: string;
}

export const FormikCheckbox = ({
  name,
  disabled,
  label,
}: FormikCheckboxProps) => {
  const [field, meta] = useField<boolean>(name);
  const error = meta.touched ? meta.error : undefined;

  return (
    <FormControl
      id={`formik-checkbox-${name}`}
      isInvalid={!!error}
      isDisabled={disabled}
      mb={4}
    >
      <Checkbox
        isChecked={field.value}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
      >
        {label}
      </Checkbox>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

interface FormikSelectProps extends BaseFieldProps {
  label?: string;
  placeholder?: string;
}

export const FormikSelect = ({
  label,
  name,
  placeholder,
  disabled,
  children,
}: PropsWithChildren<FormikSelectProps>) => {
  const [field, meta] = useField<string>(name);
  const error = meta.touched ? meta.error : undefined;

  return (
    <FormControl
      isInvalid={!!error}
      id={`formik-select-${name}`}
      isDisabled={disabled}
      mb={4}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Select {...field} placeholder={placeholder} formNoValidate>
        {children}
      </Select>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
