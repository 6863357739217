import { useCallback, useEffect, useState } from "react";
import throttle from "lodash-es/throttle";
import { hideSplashScreen } from "lib/utils";

export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeHandler = useCallback(
    throttle(
      () => {
        setWindowHeight(window.innerHeight);
      },
      300,
      { leading: true, trailing: true }
    ),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [resizeHandler]);

  return windowHeight;
};

export const useHideSplash = () => {
  useEffect(() => {
    hideSplashScreen();
  }, []);
};
