import { createIcon } from "@chakra-ui/react";

const Close = createIcon({
  displayName: "Error",
  viewBox: "0 0 70 71",
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="currentColor"
      fillRule="evenodd"
    >
      <g>
        <g>
          <path d="M437.019,74.981C388.667,26.629,324.38,0,256,0S123.333,26.63,74.981,74.981S0,187.62,0,256    s26.629,132.667,74.981,181.019C123.332,485.371,187.62,512,256,512s132.667-26.629,181.019-74.981    C485.371,388.667,512,324.38,512,256S485.371,123.332,437.019,74.981z M256,470.636C137.65,470.636,41.364,374.35,41.364,256    S137.65,41.364,256,41.364S470.636,137.65,470.636,256S374.35,470.636,256,470.636z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M341.22,170.781c-8.077-8.077-21.172-8.077-29.249,0L170.78,311.971c-8.077,8.077-8.077,21.172,0,29.249    c4.038,4.039,9.332,6.058,14.625,6.058s10.587-2.019,14.625-6.058L341.22,200.029C349.296,191.953,349.296,178.858,341.22,170.781    z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M341.22,311.971l-141.191-141.19c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l141.19,141.191    c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.586-2.019,14.625-6.058C349.296,333.143,349.296,320.048,341.22,311.971z" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  ),
  defaultProps: {
    color: "tomato.400",
    position: "absolute",
    right: "-10px",
    top: "5px",
    cursor: "pointer",
    transform: "scale(0.8)",
    transition: "all 100ms ease-out",
    _hover: {
      transform: "scale(0.75)",
    },
  },
});

export default Close;
