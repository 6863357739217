import { Text, Stack, Button } from "@chakra-ui/react";
import { Layout } from "components/shared";
import { useAxaInsurances } from "hooks/insurances";
import { HomeRoute, InsurancePurchaseRoute } from "lib/routes";
import { InsurancePurchaseType, InsuranceType } from "models/axa-products";
import { useHistory } from "react-router-dom";

export const Insurances = () => {
  const history = useHistory();
  const { insurances } = useAxaInsurances();

  return (
    <Layout title="Försäkringar" onGoBack={() => history.push(HomeRoute)}>
      <Text textStyle="p" textAlign="center" my={[10]}>
        Försäkringar är en viktig del för att skydda dig och din familj.
      </Text>

      <Stack
        spacing={[3, 4]}
        mb={[10]}
        mx="auto"
        width="100%"
        maxW={["100%", "600px"]}
      >
        <Button colorScheme="denim">Trygghetsförsäkring</Button>
        <Button
          colorScheme="tomato"
          onClick={() =>
            history.push(
              InsurancePurchaseRoute.format({
                insuranceType: InsuranceType.Life,
                purchaseType: insurances.life
                  ? InsurancePurchaseType.Upsell
                  : InsurancePurchaseType.Initial,
              })
            )
          }
        >
          Livförsäkring
        </Button>
      </Stack>

      <Text textStyle="p" textAlign="center">
        Vår Trygghetsförsäkring skyddar dig när du blir sjuk eller förlorar
        inkomst på annat sätt.
        <br /> Vår Livförsäkring ger din familj en ökad trygghet om det värsta
        skulle hända.
      </Text>
    </Layout>
  );
};
