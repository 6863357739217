import { Box, useDisclosure, Drawer, Tooltip } from "@chakra-ui/react";
import * as Icons from "../assets/icons";
import { FundsCard, HomeMenu } from "../components/home";
import { InsurancesCard } from "../components/home/InsurancesCard";
import { ClickableIcon } from "../components/shared";
import { HeapPagesEvents, trackHeapEvent } from "../lib/analytics";
import { FavoritesRoute, IntroRoute } from "../lib/routes";
import {
  getIntroWatched,
  isFirstPageVisit,
  setVisitedPage,
} from "../lib/utils";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { NewsletterCard } from "components/home/NewsletterCard";

export const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  const [isFirstVisit] = useState(isFirstPageVisit(path));
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isFirstVisit) {
      const isWatchedIntro = getIntroWatched();
      if (!isWatchedIntro) {
        history.push(IntroRoute.format({ step: 1 }));
      }

      if (isWatchedIntro) {
        setVisitedPage(path);
      }
    }
  }, [isFirstVisit, path]);

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowHomePage, {});
  }, []);

  return (
    <>
      <Box px={[8]} py={[5, 8]} maxW="600px" mx="auto">
        <Box
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={["50px"]}
        >
          <ClickableIcon
            aria-label="Show Menu"
            icon={<Icons.Menu width={["28px"]} height={["18px"]} />}
            onClick={onOpen}
          />
          <Icons.Pensa boxSize={["26px", "30px"]} />
          <ClickableIcon
            aria-label="Show Favorites"
            icon={
              <Tooltip
                hasArrow
                label={
                  <>
                    Favoritmarkera de fondportföljer
                    <br /> du vill följa
                  </>
                }
                bg="grey.100"
                color="denim.900"
                textStyle="p"
                placement="left"
                arrowSize={12}
                offset={[0, 12]}
                isOpen={isFirstVisit}
              >
                <Icons.Heart width={["18px"]} height={["16px"]} />
              </Tooltip>
            }
            onClick={() => history.push(FavoritesRoute)}
          />
        </Box>
        <FundsCard />
        {/* <InsurancesCard /> */}
        <NewsletterCard />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        autoFocus={false}
      >
        <HomeMenu />
      </Drawer>
    </>
  );
};
