import { createIcon } from "@chakra-ui/react";

const Menu = createIcon({
  displayName: "Menu",
  viewBox: "0 0 28 18",
  path: (
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor" strokeWidth="2">
        <g>
          <path
            d="M0 .421h25.263M0 8h15.158M0 15.579L25.263 15.579"
            transform="translate(-32 -63) translate(33 64)"
          />
        </g>
      </g>
    </g>
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default Menu;
