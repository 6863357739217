import { Layout } from "components/shared";
import { HomeRoute, AllPortfoliosRoute } from "lib/routes";
import { useHistory } from "react-router-dom";
import * as Icons from "assets/icons";
import { Stack, Text, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Loading } from "components/shared";
import { FavouritesList } from "../components/shared";
import { useShellRecommendationsData2 } from "hooks/pages/shellRecommendations";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";

export const Favorites = () => {
  const history = useHistory();
  const [favourites, setFavourites] = useState<any>([]);
  const [isUpdating, setUpdating] = useState(false);
  const [isFirstLoad, setFirstLoad] = useState(true);
  const [isRemoving, setRemoved] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRemoveFavourite = (idx: number) => {
    setRemoved(true);
    let favouritesTemp = favourites;
    favouritesTemp.splice(idx, 1);
    isRemoving && setFavourites(favouritesTemp);
    setRemoved(false);
  };
  const handleUpdate = async (toggleCallback: () => void) => {
    setLoading(true);
    await toggleCallback();
    setLoading(false);
  };
  const { isSavingFavorites, followed_recommendations, onToggleFavorite } =
    useShellRecommendationsData2();
  useEffect(() => {
    if (followed_recommendations && isFirstLoad) {
      setFavourites(followed_recommendations);
      setFirstLoad(false);
    }
    if (followed_recommendations && isUpdating) {
      setFavourites(followed_recommendations);
      setUpdating(false);
    }
    return () => {};
  }, [followed_recommendations, isUpdating, isFirstLoad]);

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowFavouritesPage, {});
    return () => {};
  }, []);

  return (
    <>
      <Layout title="Favoriter" onGoBack={() => history.push(HomeRoute)}>
        <Icons.Heart w={["18px"]} h={["16px"]} mb={[6]} mx="auto" />
        <Text textStyle="p" textAlign="center" mb={[10]}>
          Tips är att du favoritmarkerar <Icons.HeartOutlined mx="3px" /> de
          portföljer som du valt att ha. Då syns dom här med info hur dom går.
          <br />
          <br />
          Du får då också varje månad ett mail där du ser utvecklingen för dina
          portföljer. Enkelt och smidigt.
        </Text>

        <Stack spacing={[3, 4]}>
          {favourites && (
            <FavouritesList
              favourites={favourites}
              handleRemoveFavourite={handleRemoveFavourite}
              setUpdating={setUpdating}
              handleUpdate={handleUpdate}
              toggle={onToggleFavorite}
            />
          )}
        </Stack>
        <br />
        <br />
        <Button onClick={() => history.push(AllPortfoliosRoute)}>
          Se utveckling för alla portföljer
        </Button>
        {isSavingFavorites ? <Loading loading={loading} /> : null}
      </Layout>
    </>
  );
};
