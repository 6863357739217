import { useToast, Box, Text } from "@chakra-ui/react";
import { AuthContext } from "context/auth";
import { useCurrentUser, useUpdateFavoritesRequest } from "hooks/user";
import { FundRecommendation, RecommendationType } from "models/funds";
import { FavoritePortfolio } from "models/user";
import { useCallback, useContext, useMemo } from "react";
import { useInsuranceShells, useShellRecommendations } from "../funds";
import * as Icons from "assets/icons";

function portfolioPredicate(
  shellId: string,
  recommendationType: RecommendationType
) {
  return ({ insurance_shell, classification }: FavoritePortfolio) =>
    insurance_shell === shellId && classification === recommendationType;
}

export const useShellRecommendationsData = (
  pensionInstitute: string,
  insuranceShell: string,
  recommendationType: RecommendationType
) => {
  const toast = useToast();
  const { userId } = useContext(AuthContext);
  const {
    isLoading: isUserLoading,
    user,
    mutate: mutateUser,
  } = useCurrentUser();
  const { isLoading: isLoadingShells, shells } = useInsuranceShells();
  const { isLoading: isSavingFavorites, updateFavoritesRequest } =
    useUpdateFavoritesRequest(userId!);
  const selectedShell = useMemo(
    () =>
      shells?.find(
        ({ insurance_type, pension_institute }) =>
          insurance_type === insuranceShell &&
          pension_institute === pensionInstitute
      ),
    [pensionInstitute, insuranceShell, shells]
  );
  const { isLoading: isLoadingRecommendations, recommendations: recs } =
    useShellRecommendations(selectedShell?._id);

  const recommendations = useMemo(
    () =>
      recs?.reduce((acc, curr) => {
        acc[curr.classification] = curr;
        return acc;
      }, {} as { [key in RecommendationType]: FundRecommendation }),
    [recs]
  );

  const isFavorite = useMemo(
    () =>
      !!user?.recommendations_followed.find(
        portfolioPredicate(selectedShell?._id!, recommendationType)
      ),
    [selectedShell, user, recommendationType]
  );
  const onToggleFavorite = useCallback(async () => {
    if (!user || !selectedShell) {
      return;
    }
    const predicate = (rec: FavoritePortfolio) =>
      !portfolioPredicate(selectedShell._id, recommendationType)(rec);

    let favorites: Partial<FavoritePortfolio>[] = isFavorite
      ? user.recommendations_followed.filter(predicate)
      : [
          ...user.recommendations_followed,
          {
            insurance_shell: selectedShell._id,
            classification: recommendationType,
          },
        ];
    await updateFavoritesRequest(
      { recommendations_followed: favorites },
      {
        onSuccess: (resp) => {
          !isFavorite &&
            toast({
              position: "bottom",
              isClosable: true,
              duration: 4000,
              render: () => (
                <Box color="white" p={5} bg="#3c5577" borderRadius="4px">
                  <Text textStyle="p" fontSize={["sm", "md"]} color="white">
                    {" "}
                    Nu kan du enkelt följa hur det går för den här portföljen
                    under Favoriter <Icons.Heart />. Du får också ett email
                    varje månad med info hur dina favoritportföljer går. Även om
                    du favoritmarkerar behöver du själv gå in och byta fonder.
                  </Text>
                </Box>
              ),
            });
          mutateUser(resp, false);
        },
      }
    );
  }, [
    updateFavoritesRequest,
    user,
    isFavorite,
    selectedShell,
    mutateUser,
    recommendationType,
  ]);

  return {
    isLoading: isLoadingShells || isLoadingRecommendations || isUserLoading,
    isFavorite,
    recommendations,
    isSavingFavorites,
    fundChangeLink: selectedShell?.link,
    onToggleFavorite,
  };
};
export const useShellRecommendationsData2 = () => {
  const { userId } = useContext(AuthContext);
  const {
    isLoading: isUserLoading,
    user,
    mutate: mutateUser,
  } = useCurrentUser();
  const { isLoading: isLoadingShells } = useInsuranceShells();
  const { isLoading: isSavingFavorites, updateFavoritesRequest } =
    useUpdateFavoritesRequest(userId!);
  const followed_recommendations = useMemo(() => {
    return user?.recommendations_followed;
  }, [user?.recommendations_followed]);
  const onToggleFavorite = useCallback(
    async (
      selectedShell: string = "",
      recommendationType: RecommendationType,
      isCancel: boolean
    ) => {
      if (!user || !selectedShell) {
        return;
      }
      const isFavorite = () =>
        !!followed_recommendations?.find(
          portfolioPredicate(selectedShell!, recommendationType)
        );

      const predicate = (rec: FavoritePortfolio) =>
        !portfolioPredicate(selectedShell, recommendationType)(rec);

      let favorites: Partial<FavoritePortfolio>[] =
        [] as Partial<FavoritePortfolio>[];

      if (!isCancel) {
        favorites = followed_recommendations?.filter(
          predicate
        ) as Partial<FavoritePortfolio>[];
      }
      if (isCancel) {
        let isSame = false;
        favorites = user.recommendations_followed;
        favorites.forEach((rec: Partial<FavoritePortfolio>) => {
          if (String(rec.insurance_shell) === String(selectedShell)) {
            isSame = true;
          }
        });
        if (!isSame || !isFavorite()) {
          favorites.push({
            insurance_shell: selectedShell,
            classification: recommendationType,
          });
        }
      }

      await updateFavoritesRequest(
        { recommendations_followed: favorites },
        {
          onSuccess: (resp) => {
            mutateUser(resp, false);
          },
        }
      );
    },
    [updateFavoritesRequest, user, followed_recommendations, mutateUser]
  );

  return {
    isLoading: isLoadingShells || isUserLoading,
    isSavingFavorites,
    onToggleFavorite,
    followed_recommendations,
  };
};
