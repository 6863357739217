import { createIcon } from "@chakra-ui/react";

const Pensa = createIcon({
  displayName: "Pensa",
  viewBox: "0 0 113 120",
  path: (
    <g fill="none" fillRule="evenodd">
      <path d="M-4 0h120v120H-4z" />
      <path
        fill="currentColor"
        d="M37.161 71.529V55.49l13.327 8.004v16.04L37.16 71.528zm38.32 13.946l11.668-6.989 24.979-15.007V19.518l-8.736 5.249-16.243 9.758V4.51l-8.75 5.264-16.244 9.744V0H50.488v19.518L34.245 9.774 25.494 4.51v30.015L9.25 24.767.5 19.517V63.48l24.816 15.023 11.845 6.973 13.327 8.004V120h11.667V93.479l13.326-8.004zm11.668-20.95V48.486l13.311-8.004v16.055L87.15 64.525zM75.481 41.514l-13.326 8.004V33.494l13.326-8.004v16.024zm-13.326 21.98l13.326-8.004v16.039l-13.326 8.004V63.494zM12.168 40.482l13.326 8.004v16.04l-13.326-7.99V40.483zm38.32 9.036L37.16 41.514V25.49l13.327 8.004v16.024z"
      />
    </g>
  ),
  defaultProps: {
    color: "tomato.400",
  },
});

export default Pensa;
