import { createIcon } from "@chakra-ui/react";

const Email = createIcon({
  displayName: "Email",
  viewBox: "0 0 32 21",
  path: (
    <g fill="none" fillRule="nonzero">
      <path d="M0-5h32v32H0z" />
      <path
        fill="currentColor"
        d="M.027 20.391h31.946V.425H.027v19.966zM28.256 2.425L16 11.205 3.727 2.425h24.529zM2.027 3.667L16 13.666 29.973 3.654v14.737H2.027V3.667z"
      />
    </g>
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default Email;
