import { BlockerScreen, BlockerScreenType } from "components/shared";
import { useHideSplash } from "hooks/layout";
import { ErrorRouteQuery } from "lib/routes";
import { useQuery } from "lib/utils";
import { useHistory } from "react-router-dom";

export const Error = () => {
  useHideSplash();

  const history = useHistory();
  const query = useQuery<ErrorRouteQuery>();

  const hasBtn = !!query.redirectUrl;
  const shouldReload = !!query.reload;

  return (
    <BlockerScreen
      type={BlockerScreenType.Error}
      title={query.title || "Hoppsan!"}
      content={query.message || "Något gick fel, försök igen"}
      btnText={hasBtn ? "Gå tillbaka" : undefined}
      onClick={
        hasBtn
          ? () =>
              shouldReload
                ? (window.location.href = query.redirectUrl!)
                : history.push(query.redirectUrl!)
          : undefined
      }
    ></BlockerScreen>
  );
};
