import { getColor } from "@chakra-ui/theme-tools";

const input = {
  baseStyle: {
    field: {
      color: "grey.500",
    },
  },

  sizes: {
    md: {
      field: {
        fontSize: ["md", "lg"],
        height: "auto",
        paddingBottom: "0.625rem",
      },
    },
  },

  variants: {
    flushed: ({ theme }: any) => {
      return {
        field: {
          _invalid: {
            borderColor: "red.500",
            color: "red.400",
            boxShadow: "none",
          },

          _focus: {
            borderColor: "grey.400",
            boxShadow: `0px 1px 0px 0px ${getColor(theme, "grey.400")}`,
          },

          _disabled: {
            opacity: "0.4",
          },
        },
      };
    },
  },

  defaultProps: {
    variant: "flushed",
    colorScheme: "grey",
  },
};

export default input;
