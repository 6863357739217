import {
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import * as Icons from "assets/icons";
import { useLogoutRequest } from "hooks/auth";
import { useWindowHeight } from "hooks/layout";
import { useCurrentUser } from "hooks/user";
import { LoginRoute, SettingsRoute, SupportRoute } from "lib/routes";
import { localStorageRemoveLogin } from "lib/utils";
import { useHistory } from "react-router-dom";
import { cache } from "swr";

export const HomeMenu = () => {
  const { user, isLoading } = useCurrentUser(false);
  const height = useWindowHeight();
  const history = useHistory();
  const { logoutRequest, isLoading: isLoggingOut } = useLogoutRequest();

  return (
    <DrawerOverlay>
      <DrawerContent pb={8} maxH={height}>
        <DrawerHeader p={6} bgColor="#f0f0f0">
          <Box d="flex" alignItems="center">
            <Skeleton borderRadius="50%" isLoaded={!isLoading}>
              <Icons.Profile boxSize={[10, 14]} />
            </Skeleton>
            <Box ml={[4, 6]}>
              <Skeleton isLoaded={!isLoading}>
                <Text textStyle="h3" as="h3">
                  {user?.full_name}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!isLoading}>
                <Text textStyle="p" fontSize={["sm", "md"]}>
                  {user?.email}
                </Text>
              </Skeleton>
            </Box>
          </Box>
        </DrawerHeader>

        <DrawerBody pt={[6, 8]}>
          <Button
            textStyle="h3"
            variant="link"
            justifyContent="flex-start"
            leftIcon={<Icons.Help boxSize={[6, 8]} mr={2} />}
            py={4}
            onClick={() => history.push(SupportRoute)}
          >
            Support
          </Button>
          <Button
            textStyle="h3"
            variant="link"
            justifyContent="flex-start"
            py={4}
            leftIcon={<Icons.Settings boxSize={[6, 8]} mr={2} />}
            onClick={() => history.push(SettingsRoute)}
          >
            Inställningar
          </Button>
        </DrawerBody>
        <DrawerFooter justifyContent="flex-start">
          <Button
            variant="link"
            colorScheme="tomato"
            fontSize={["md", "lg"]}
            width="fit-content"
            disabled={isLoggingOut}
            onClick={() => {
              logoutRequest(undefined, {
                onSuccess: () => {
                  localStorageRemoveLogin();
                  history.push(LoginRoute);
                  cache.clear();
                },
              });
            }}
          >
            Logga Ut
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </DrawerOverlay>
  );
};
