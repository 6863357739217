import { Box, Text, Skeleton } from "@chakra-ui/react";
import { Card, CardTitle, CardContent, StarsBar } from "components/shared";

interface PortfolioInfoStarItemProps {
  isLoading: boolean;
  title: string;
  portfolioRating: number;
  averageRating: number;
  insuranceShell: string;
  pensionInstitute: string;
}

export const PortfolioInfoStarItem = ({
  isLoading,
  title,
  portfolioRating,
  averageRating,
  insuranceShell,
  pensionInstitute,
}: PortfolioInfoStarItemProps) => {
  return (
    <Card>
      <CardTitle>{title}</CardTitle>
      <Skeleton isLoaded={!isLoading}>
        <CardContent>
          <StarsBar
            value={portfolioRating}
            mx="auto"
            mt={4}
            mb={[3, 4]}
            w={["190px", "300px"]}
            height={[7, 9]}
          />
          <Text mb={7} color="denim.600" textStyle="h1" textAlign="center">
            {portfolioRating.toLocaleString("sv", {
              maximumFractionDigits: 1,
            })}
          </Text>
          <Box d="flex" alignItems="flex-start" justifyContent="center">
            <StarsBar value={averageRating} mr={2} w="auto" height={[3, 4]} />
            <Text textStyle="label" fontWeight="bold" mr={[1, 2]}>
              {averageRating.toLocaleString("sv", {
                maximumFractionDigits: 1,
              })}
            </Text>
            <Text textStyle="label">
              Snitt för {pensionInstitute} - {insuranceShell}
            </Text>
          </Box>
        </CardContent>
      </Skeleton>
    </Card>
  );
};

export const PortfolioInfoStarItemSmall = ({
  isLoading,
  title,
  portfolioRating,
  averageRating,
  insuranceShell,
  pensionInstitute,
}: PortfolioInfoStarItemProps) => {
  return (
    <Card boxShadow="none">
      <Text color="denim.600" textStyle="h6" textAlign="center">
        {title}
      </Text>
      <Skeleton isLoaded={!isLoading}>
        <CardContent>
          <StarsBar
            value={portfolioRating}
            mx="auto"
            mb={[2, 3]}
            w={["144px", "150px"]}
            height={[4, 5]}
          />
          <Text mb={5} color="denim.600" textStyle="h2" textAlign="center">
            {portfolioRating.toLocaleString("sv", {
              maximumFractionDigits: 1,
            })}
          </Text>
          <Box
            d="flex"
            alignItems="flex-start"
            justifyContent="center"
            flexWrap="wrap"
          >
            <StarsBar value={averageRating} mr={2} w="auto" height={[3, 4]} />
            <Text textStyle="label" fontWeight="bold" mr={[1, 2]}>
              {averageRating.toLocaleString("sv", {
                maximumFractionDigits: 1,
              })}
            </Text>
            <Text textStyle="label">
              Snitt för {pensionInstitute} - {insuranceShell}
            </Text>
          </Box>
        </CardContent>
      </Skeleton>
    </Card>
  );
};
