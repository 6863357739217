import { LifeInsuranceBenefits } from "./Benefits";
import { LifeInsuranceQuestions } from "./Questions";
import { LifeInsurancBankDetails } from "./BankDetails";
import { LifeInsuranceSummary } from "./Summary";
import { InsurancePurchaseConfig } from "models/insurance-purchase";

export const LifeInsuranceConfig: InsurancePurchaseConfig = {
  BenefitsComponent: LifeInsuranceBenefits,
  QuestionsComponent: LifeInsuranceQuestions,
  BankDetailsComponent: LifeInsurancBankDetails,
  SummaryComponent: LifeInsuranceSummary,
};
