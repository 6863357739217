import * as React from "react";
import throttle from "lodash-es/throttle";

import "./WithInnerHeight.scss";

interface WithInnerHeightProps {
  children: React.ReactNode;
  limitHeight?: boolean;
  limitHeightTo?: number;
}

interface WithInnerHeightState {
  height: number;
}

export default class WithInnerHeight extends React.Component<
  WithInnerHeightProps,
  WithInnerHeightState
> {
  readonly state: WithInnerHeightState = {
    height: window.innerHeight,
  };

  setHeight = throttle(() => {
    this.setState({
      height: window.innerHeight,
    });
  }, 300);

  componentDidMount() {
    window.addEventListener("resize", this.setHeight);
    window.addEventListener("touchmove", this.setHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setHeight);
    window.addEventListener("touchmove", this.setHeight);
  }

  render() {
    const limitedHeight =
      this.props.limitHeightTo === undefined
        ? this.state.height
        : this.props.limitHeightTo;
    return (
      <div
        className="WithInnerHeight"
        style={{
          minHeight: this.props.limitHeight ? limitedHeight : this.state.height,
          height: this.props.limitHeight ? limitedHeight : "auto",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
