import { Button, Text, Stack, Skeleton, Link } from "@chakra-ui/react";
import { Layout } from "components/shared";
import { BenefitsComponentProps } from "models/insurance-purchase";
import React, { FC } from "react";

export const LifeInsuranceBenefits: FC<BenefitsComponentProps> = ({
  alternatives,
  onPrev,
  onNext,
}) => {
  return (
    <Layout title="Fördelarna för dig" onGoBack={onPrev}>
      <Text textStyle="p" mb={[10]}>
        En livförsäkring är extra värdefull om du har familj eller om ni har
        olika inkomster i hushållet. Din familj kan få ekonomisk ersättning för
        den närmaste tiden och har råd att behålla boendet. Inga snabba
        ekonomiska beslut behöver tas – dina anhöriga kan i lugn och ro fundera
        på hur de vill lägga upp sin ekonomi i framtiden.
        <br />
        <br />
        Genom att teckna en livförsäkring kan din familj få en ekonomisk
        trygghet. Här kan du läsa mer:
        <br />
        <Link
          target="_blank"
          href="https://www.pensionskraft.se/livforsakring/"
          rel="noreferrer"
          fontWeight="semibold"
        >
          www.pensionskraft.se/livforsakring
        </Link>
      </Text>
      <Stack spacing={[3, 4]}>
        {alternatives ? (
          alternatives.coverAmounts.map((amount) => (
            <Button
              key={`life_insurance_${amount}`}
              colorScheme="tomato"
              onClick={() => onNext(amount)}
            >
              {amount.toLocaleString("sv")} kr (
              {alternatives.grossMonthlyPremium[amount].toLocaleString("sv")}{" "}
              kr/mån)
            </Button>
          ))
        ) : (
          <>
            <Skeleton height={[12]} />
            <Skeleton height={[12]} />
            <Skeleton height={[12]} />
          </>
        )}
      </Stack>
    </Layout>
  );
};
