import { Button } from "@chakra-ui/react";
import {
  Card,
  CardContent,
  CardTitle,
  FormikTextInput,
  Layout,
} from "components/shared";
import { Form, Formik } from "formik";
import { useSettingsPageData } from "hooks/pages/settings";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";
import { HomeRoute } from "lib/routes";
import {
  EmailSchema,
  PersonNumberSchema,
  RequiredStringSchema,
} from "lib/yup-schema";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { object } from "yup";

export const SettingsFormSchema = object().shape({
  firstName: RequiredStringSchema,
  lastName: RequiredStringSchema,
  personNumber: PersonNumberSchema,
  email: EmailSchema,
  phone: RequiredStringSchema,
});

export const Settings = () => {
  const history = useHistory();

  const { isSaving, initialValues, onSubmit } = useSettingsPageData();

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowSettingsPage, {});
    return () => {};
  }, []);

  return (
    <Layout title="Inställningar" onGoBack={() => history.push(HomeRoute)}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={SettingsFormSchema}
      >
        {(props) => (
          <Form
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <Card>
              <CardTitle>Personuppgifter</CardTitle>
              <CardContent>
                <FormikTextInput
                  label="Tilltalsnamn"
                  name="firstName"
                  disabled={isSaving}
                />
                <FormikTextInput
                  label="Efternamn"
                  name="lastName"
                  disabled={isSaving}
                />
                <FormikTextInput
                  label="Personnummer"
                  name="personNumber"
                  disabled
                />
                <FormikTextInput
                  label="E-post"
                  name="email"
                  type="email"
                  disabled={isSaving}
                />
                <FormikTextInput
                  label="Mobiltelefonnummer"
                  name="phone"
                  type="tel"
                  disabled={isSaving}
                />
              </CardContent>
            </Card>
            <Button
              type="submit"
              colorScheme="tomato"
              position="relative"
              disabled={!props.dirty}
              isLoading={isSaving}
              mt="auto"
            >
              Spara
            </Button>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};
