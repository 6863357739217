import { useToken, Icon } from "@chakra-ui/react";

interface MeterStarProps {
  progress: number; // from 0 to 1
}

let id = 0;

export const MeterStar = ({ progress }: MeterStarProps) => {
  const width = progress * 22;
  id += 1;
  const filled = "filledMeterStar" + id;
  const notFilled = "notFilledMeterStar" + id;
  const [denim600, grey100] = useToken("colors", ["denim.600", "grey.100"]);

  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      w="auto%"
      h="100%"
      viewBox="0 0 22 21"
    >
      <defs>
        <path
          id={`star${id}`}
          d="M4.434 20.99L11.07 17.5l6.635 3.488-1.267-7.388 5.367-5.232-7.418-1.078L11.07.569 7.752 7.291.333 8.369 5.701 13.6z"
        />
      </defs>
      <clipPath id={filled}>
        <rect x="0" y="0" width={width} height="21" />
      </clipPath>
      <clipPath id={notFilled}>
        <rect x={width} y="0" width={22 - width} height="21" />
      </clipPath>

      <use
        fill={denim600}
        fillRule="nonzero"
        xlinkHref={`#star${id}`}
        clipPath={`url(#${filled})`}
      />
      <use
        fill={grey100}
        fillRule="nonzero"
        xlinkHref={`#star${id}`}
        clipPath={`url(#${notFilled})`}
      />
    </Icon>
  );
};
