import { extendTheme } from "@chakra-ui/react";
import foundations from "./foundations";

import {
  Accordion,
  Button,
  Input,
  FormLabel,
  Checkbox,
  FormError,
  Tabs,
  Modal,
  Tooltip,
  Select,
} from "./components";

const pensaTheme = extendTheme({
  ...foundations,
  components: {
    Accordion,
    Button,
    Input,
    FormLabel,
    Checkbox,
    FormError,
    Tabs,
    Modal,
    Tooltip,
    Select,
  },
  styles: {
    global: {
      "*": {
        WebkitTapHighlightColor: "transparent",
      },
    },
  },
});

export default pensaTheme;
