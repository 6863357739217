import { createContext } from "react";

interface AuthContextValue {
  userId: string | null;
  setUserId(value: string): void;
}

const defaultValue = {
  userId: null,
  setUserId(val: string) {},
};
export const AuthContext = createContext<AuthContextValue>(defaultValue);
