import {
  HomeRoute,
  PensionInstitutesRoute,
  InsuranceShellsRoute,
  SettingsRoute,
  SupportRoute,
  InsurancesRoute,
  ShellRecommendationsRoute,
  FavoritesRoute,
  PortfolioInfoRoute,
  AllPortfoliosRoute,
  InsurancePurchaseRoute,
  NewslettersRoute,
} from "lib/routes";
import {
  Home,
  InsuranceShells,
  PensionInstitutes,
  Settings,
  ShellRecommendations,
  Support,
  Favorites,
  Insurances,
  InsurancePurchase,
  PortfolioInfo,
  AllPortfolios,
} from "pages";
import { Newsletters } from "pages/Newsletters";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { EmailModal } from "./EmailModal";

import { useAuthenticatedAppData } from "./hooks";

export function AuthenticatedApp() {
  useAuthenticatedAppData();

  return (
    <>
      <Switch>
        <Route path={HomeRoute} exact>
          <Home />
        </Route>

        <Route path={PensionInstitutesRoute} exact>
          <PensionInstitutes />
        </Route>

        <Route path={InsuranceShellsRoute.template} exact>
          <InsuranceShells />
        </Route>

        <Route path={ShellRecommendationsRoute.template} exact>
          <ShellRecommendations />
        </Route>

        <Route path={PortfolioInfoRoute.template} exact>
          <PortfolioInfo />
        </Route>

        <Route path={FavoritesRoute} exact>
          <Favorites />
        </Route>
        <Route path={AllPortfoliosRoute} exact>
          <AllPortfolios />
        </Route>

        <Route path={SettingsRoute} exact>
          <Settings />
        </Route>

        <Route path={SupportRoute} exact>
          <Support />
        </Route>

        <Route path={InsurancesRoute} exact>
          <Insurances />
        </Route>
        <Route path={NewslettersRoute} exact>
          <Newsletters />
        </Route>

        <Route path={InsurancePurchaseRoute.template} exact>
          <InsurancePurchase />
        </Route>

        <Route path="*">
          <Redirect to={HomeRoute} />
        </Route>
      </Switch>
      <EmailModal />
    </>
  );
}
