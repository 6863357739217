import React, { useState, useEffect } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import {
  ChakraProvider,
  createStandaloneToast,
  CloseButton,
  Box,
  Button,
  Text,
} from "@chakra-ui/react";

import { AuthenticatedApp } from "components/authenticated-app";
import {
  LoginRoute,
  AuthCodeRoute,
  LoginWaitingRoute,
  ErrorRoute,
  IntroRoute,
} from "lib/routes";

import { Login, AuthCodePage, LoginWaiting, Error } from "pages";
import theme from "theme";
import { AuthContext } from "context/auth";
import { useIosInstallPrompt, useWebInstallPrompt } from "hooks/app";
import { ShareIcon } from "assets/icons";
import { Intro } from "components/intro/intro";

const toast = createStandaloneToast({ theme });

export const App = () => {
  const [userId, setUserId] = useState<string | null>(null);

  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  const handleClose = () => {
    if (iosInstallPrompt) {
      handleIOSInstallDeclined();
    }
    if (webInstallPrompt) {
      handleWebInstallDeclined();
    }
    toast.closeAll();
  };

  useEffect(() => {
    if (webInstallPrompt || iosInstallPrompt) {
      toast({
        position: "bottom",
        duration: 1000000,
        render: () => (
          <>
            <Box
              bgColor="white"
              boxShadow="default"
              px={[4]}
              py={[4]}
              zIndex={10000}
              width={["90vw", "400px"]}
              position="relative"
            >
              <CloseButton
                position="absolute"
                top={[2]}
                right={[2]}
                color="grey.400"
                onClick={() => handleClose()}
              />
              <Text textStyle="h3" mb={[4]} paddingRight="20px">
                Lägg till Pensionskraft på Hemskärmen
              </Text>
              {iosInstallPrompt && (
                <Box>
                  Klicka <ShareIcon className="" modern />
                  &quot;för att Lägga till på Hemskärmen&quot;
                </Box>
              )}
              {webInstallPrompt && (
                <Button
                  colorScheme="tomato"
                  px={[4]}
                  py={[2]}
                  minH={["auto", "auto"]}
                  onClick={() => {
                    handleWebInstallAccepted();
                    handleClose();
                  }}
                >
                  Lägg till
                </Button>
              )}
            </Box>
          </>
        ),
      });
    }
  }, [webInstallPrompt, iosInstallPrompt]);

  return (
    <AuthContext.Provider value={{ userId, setUserId }}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path={LoginRoute} exact>
              <Login />
            </Route>
            <Route path={LoginWaitingRoute} exact>
              <LoginWaiting />
            </Route>
            <Route path={AuthCodeRoute} exact>
              <AuthCodePage />
            </Route>
            <Route path={ErrorRoute.template} exact>
              <Error />
            </Route>
            <Route path={IntroRoute.template} exact>
              <Intro />
            </Route>
            <Route path="*">
              <AuthenticatedApp />
            </Route>
          </Switch>
        </BrowserRouter>
      </ChakraProvider>
    </AuthContext.Provider>
  );
};
