import { RecommendationType } from "./funds";

export enum UserEventCode {
  PPMConfirmed = "ppm-consent-received",
}

export interface UserModel {
  _id: string;
  full_name: string;
  name: {
    last: string;
    first: string;
  };
  events: {
    code: UserEventCode;
  }[];
  email: string;
  email_status?: string;
  phone: {
    mobile: string;
  };
  person_number: string;
  recommendations_followed: FavoritePortfolio[];
}

export interface FavoritePortfolio {
  insurance_shell: string;
  classification: RecommendationType;
  date: string;
}
export interface UpdateUserRequestBody {
  name: {
    last: string;
    first: string;
  };
  email: string;
  phone: {
    mobile: string;
  };
}

export interface UpdateFavoritesRequestBody {
  recommendations_followed: Partial<FavoritePortfolio>[];
}
