const baseStyleButton = {
  _focus: {
    boxShadow: "none",
  },
  _hover: {
    boxShadow: "none",
    bg: "blackAlpha.50",
  },
  px: [7, 10],
  py: [4, 6],
};

const baseStylePanel = {
  px: [7, 10],
  py: [4, 6],
};

const baseStyle = {
  button: baseStyleButton,
  panel: baseStylePanel,
};

const accordion = { baseStyle };

export default accordion;
