import  React from "react";
import cn from "classnames";
import { createPortal } from "react-dom";

import { DoubleBounce } from "../doubleBounce";

import "./LoadingOverlay.scss";

interface LoadingOverlayProps {
  isShown: boolean;
}

export default class LoadingOverlay extends React.Component<LoadingOverlayProps, {}> {
  render() {
    const { isShown } = this.props;
    return createPortal(
      <div
        className={cn("LoadingOverlay", { "LoadingOverlay--shown": isShown })}
      >
        {isShown && <DoubleBounce />}
      </div>,
      document.body
    );
  }
}
