import { Box } from "@chakra-ui/react";
import { ChackraSize } from "lib/types";
import { MotionBox } from "./MotionBox";

interface DobuleBounceProps {
  size: ChackraSize;
}

export const DoubleBounce = ({ size }: DobuleBounceProps) => {
  return (
    <Box boxSize={size} position="relative">
      <Bounce color="tomato.400" first />
      <Bounce color="tomato.400" />
    </Box>
  );
};

interface BounceProps {
  first?: boolean;
  color: string;
}

const Bounce = ({ first, color }: BounceProps) => (
  <MotionBox
    position="absolute"
    top="0"
    left="0"
    boxSize="100%"
    borderRadius="50%"
    opacity="0.6"
    animate={{ scale: first ? [0, 1] : [1, 0] }}
    bgColor={color}
    transition={{ repeat: Infinity, repeatType: "reverse" }}
  />
);
