import { Layout } from "components/shared";
import { FavoritesRoute } from "lib/routes";
import { useHistory } from "react-router-dom";
import { Stack, Text } from "@chakra-ui/react";
import {
  PortfolioPerformance,
  EachPortfolioPerformance,
} from "components/favorites";
import { useInsuranceShells } from "hooks/funds";
import { RecommendationType } from "models/funds";
import { Fragment, useEffect } from "react";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";

export const AllPortfolios = () => {
  const history = useHistory();
  const { shells } = useInsuranceShells();

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowAllPortfoliosPage, {});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout
      title="Alla portföljer"
      onGoBack={() => history.push(FavoritesRoute)}
    >
      <Text textStyle="p" textAlign="center" mb={[10]}>
        Här ser du utvecklingen för alla portföljer som vi tagit fram.
      </Text>

      <Stack spacing={[3, 4]}>
        {shells?.map(({ _id, show_to_user }) => (
          <Fragment key={show_to_user + _id}>
            <EachPortfolioPerformance
              shellId={_id}
              type={RecommendationType.Sinful}
            />
            <EachPortfolioPerformance
              shellId={_id}
              type={RecommendationType.Sustainable}
            />
          </Fragment>
        ))}
      </Stack>
    </Layout>
  );
};
