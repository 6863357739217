import { AuthContext } from "context/auth";
import { useApiSWR, useMutationRequest } from "./api";
import { UserById, WhoAmI, PPMConsentGiven } from "api/endpoints";
import { LoginRoute } from "lib/routes";
import { WhoAmIResponse } from "models/auth";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  UpdateUserRequestBody,
  UserModel,
  UpdateFavoritesRequestBody,
  UserEventCode,
} from "models/user";
import { localStorageRemoveLogin } from "lib/utils";

export const useCurrentUser = (revalidate?: boolean) => {
  const { userId } = useContext(AuthContext);

  const { data, error, mutate } = useApiSWR<UserModel>(
    userId ? UserById(userId) : undefined,
    { revalidateOnMount: revalidate }
  );

  return {
    isLoading: userId && !(error || data),
    mutate,
    user: data,
  };
};

export const useWhoAmI = () => {
  const { userId, setUserId } = useContext(AuthContext);

  const history = useHistory();

  const { data: whoAmI, error: whoAmIError } = useApiSWR<WhoAmIResponse>(
    userId ? undefined : WhoAmI
  );

  useEffect(() => {
    if (whoAmI?._id) {
      setUserId(whoAmI._id);
    }
  }, [whoAmI, setUserId]);

  useEffect(() => {
    if (whoAmIError?.status === 404) {
      localStorageRemoveLogin();
      history.push(LoginRoute);
    }
  }, [whoAmIError, whoAmI, history]);
};

export const useUpdateUserRequest = (userId: string) => {
  const { isLoading, request } = useMutationRequest<
    Partial<UpdateUserRequestBody>,
    UserModel
  >(UserById(userId));

  return {
    updateUserRequest: request,
    isLoading,
  };
};

export const useGivePPMConsentRequest = (userId: string) => {
  const { isLoading, request } = useMutationRequest<{}, UserModel>(
    PPMConsentGiven(userId),
    "PUT"
  );

  return {
    givePPMConsent: request,
    isLoading,
  };
};

export const useUpdateFavoritesRequest = (userId: string) => {
  const { isLoading, request } = useMutationRequest<
    UpdateFavoritesRequestBody,
    UserModel
  >(UserById(userId));

  return {
    updateFavoritesRequest: request,
    isLoading,
  };
};

export const usePPMConsent = () => {
  const { user, mutate } = useCurrentUser();
  const isConsentGiven = useMemo(
    () =>
      Boolean(user?.events?.find((e) => e.code === UserEventCode.PPMConfirmed)),
    [user]
  );
  const { isLoading, givePPMConsent } = useGivePPMConsentRequest(user?._id!);
  const onGiveConsent = useCallback(
    ({ onSuccess }) => {
      givePPMConsent(
        {},
        {
          onSuccess: (res) => {
            mutate(res, false);
            onSuccess();
          },
        }
      );
    },
    [givePPMConsent, mutate]
  );

  return {
    isLoading,
    isUserLoaded: !!user,
    isConsentGiven,
    onGiveConsent,
  };
};
