import {
  Stack,
  Text,
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
} from "@chakra-ui/react";
import { Layout, Card, CardTitle, CardContent } from "components/shared";
import { HomeRoute } from "lib/routes";
import { useHistory } from "react-router-dom";
import * as Icons from "assets/icons";
import { useEffect } from "react";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";

const minpensionLink = (
  <Link
    target="_blank"
    href="http://www.minpension.se"
    rel="noreferrer"
    fontWeight="semibold"
  >
    www.minpension.se
  </Link>
);

const faq = [
  {
    q: "Hur kan jag se var mina pengar ligger?",
    a: (
      <>
        Enklast ser du det genom att logga in på {minpensionLink}. Där finns en
        sammanställning på hur mycket du har sparat i pension och var pengarna
        ligger.
      </>
    ),
  },
  {
    q: "Hur går det för mina fonder?",
    a: (
      <>
        Varje månad görs det en sammanställning över hur fonderna gått och
        skickas ut ett email till dig om det. Kom ihåg att favoritmarkera de
        fondportföljer du vill följa för då får du sammanställningen i ett email
        till dig varje månad. Annars kan du alltid hitta det i appen under varje
        fondportfölj.
      </>
    ),
  },
  {
    q: "Vilka delar av pensionen får jag hjälp med?",
    a: (
      <>
        Vi har fondportföljer för alla de större pensionsbolagen och deras olika
        fondförsäkringar. Det omfattar både PPM, tjänste- och privatpensionen.
      </>
    ),
  },
  {
    q: "Hur mycket får jag i pension?",
    a: (
      <>
        Det beror på hur utvecklingen på ditt pensionskapital blir och hur
        mycket du har sparat såklart. Vill du se en prognos, kan du logga in på
        {minpensionLink}.
      </>
    ),
  },
  {
    q: "Hur gör jag för att göra ett fondbyte till er fondportfölj?",
    a: (
      <>
        Det gör du enkelt genom att logga in på det försäkringsbolaget eller
        PPM Pensionsmyndigheten (PPM) som du vill byta fonderna på.
      </>
    ),
  },
  {
    q: "Hur avslutar jag tjänsten?",
    a: (
      <>
        Vad tråkigt om du vill lämna tjänsten. Men om du har bestämt dig för det
        så kontaktar du kundtjänst för att avsluta.
      </>
    ),
  },
];

export const Support = () => {
  const history = useHistory();

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowSupportPage, {});
  }, []);

  return (
    <Layout title="Support" onGoBack={() => history.push(HomeRoute)}>
      <Stack spacing={[3, 4]}>
        <Card>
          <CardContent>
            <Text mb={[4, 6]} textStyle="p">
              Vill du komma i kontakt med oss?
            </Text>
            <Stack spacing={[6]}>
              <Box d="flex" flexDir="column" alignItems="center">
                <Icons.Email boxSize={[8, 10]} />
                <Text textStyle="p" mb={3}>
                  kundtjanst@pensionskraft.se
                </Text>
                <Button
                  colorScheme="tomato"
                  as="a"
                  href="mailto:kundtjanst@pensionskraft.se"
                  width={["170px", "200px"]}
                >
                  Maila oss
                </Button>
              </Box>
              <Box d="flex" flexDir="column" alignItems="center">
                <Icons.Phone boxSize={[8, 10]} mb={[4]} />
                <Text textStyle="p" mb={1}>
                  Vardagar 09:00 - 12:00
                </Text>
                <Text textStyle="h3" mb={[3]}>
                  063 – 501 16 20
                </Text>
                <Button
                  colorScheme="tomato"
                  as="a"
                  href="tel:+46635011620"
                  width={["170px", "200px"]}
                >
                  Ring oss
                </Button>
              </Box>
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardTitle>Vanliga Frågor</CardTitle>
          <Accordion allowMultiple colorScheme="grey">
            {faq.map(({ q, a }, index) => (
              <AccordionItem key={index}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text textStyle="p" fontWeight="semibold">
                      {q}
                    </Text>
                  </Box>
                  <AccordionIcon color="grey.400" />
                </AccordionButton>
                <AccordionPanel>
                  <Text textStyle="p">{a}</Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Card>
      </Stack>
    </Layout>
  );
};
