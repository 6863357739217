import { Box, Text } from "@chakra-ui/react";
import { FundRecommendation } from "models/funds";

interface RecommendedFundsProps {
  recommendations: FundRecommendation;
}

export const RecommendedFunds = ({
  recommendations,
}: RecommendedFundsProps) => (
  <>
    {recommendations?.funds?.map(({ _id, fund_in_shell, allocation }, i) => (
      <Box
        key={_id}
        d="flex"
        justifyContent="space-between"
        mb={i === recommendations.funds.length - 1 ? 0 : [3, 4]}
        w="100%"
      >
        <Box w="100%" mr={[2, 3]}>
          <Text textStyle="p" color="denim.600" fontWeight="bold">
            {fund_in_shell?.fund?.name}
          </Text>
          {fund_in_shell?.id_in_shell && (
            <Text textStyle="p">{fund_in_shell?.id_in_shell}</Text>
          )}
        </Box>
        <Text textStyle="p">{allocation}%</Text>
      </Box>
    ))}
  </>
);
