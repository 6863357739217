import { useInsuranceShells } from "hooks/funds";
import { useCurrentUser, useUpdateUserRequest, useWhoAmI } from "hooks/user";
import { hideSplashScreen, localStorageSetLogin } from "lib/utils";
import { useCallback, useEffect } from "react";

export const useAuthenticatedAppData = () => {
  useWhoAmI();
  const { revalidate } = useInsuranceShells();

  const { user } = useCurrentUser();
  useEffect(() => {
    if (user) {
      localStorageSetLogin();
      hideSplashScreen();
    }
  }, [user]);

  useEffect(() => {
    revalidate();
  }, [revalidate]);
};

interface EmailModalFormValues {
  email: string;
}

const initialValues: EmailModalFormValues = {
  email: "",
};

export const useEmailModalData = () => {
  const { user, mutate } = useCurrentUser();

  const { isLoading, updateUserRequest } = useUpdateUserRequest(user?._id!);
  const onSubmit = useCallback(
    (values: EmailModalFormValues) => {
      updateUserRequest(
        { email: values.email },
        { onSuccess: (res) => mutate(res, false) }
      );
    },
    [updateUserRequest, mutate]
  );

  return {
    isSaving: isLoading,
    isOpened: !!(user && (!user.email || user.email_status === "incorrect")),
    initialValues,
    onSubmit,
  };
};
