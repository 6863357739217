import  React from "react";
import cn from "classnames";

import "./RippleEffect.scss";

interface RippleEffectProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  actionFirst?: boolean;
  inverse?: boolean;
  circled?: boolean;
  rounded?: boolean;
  inline?: boolean;

  onClick?(): any;
}

interface RippleEffectState {
  active: boolean;
  rippleStyles: {
    left?: number;
    top?: number;
    width?: number;
    height?: number;
  };
}

export default  class RippleEffect extends React.Component<
  RippleEffectProps,
  RippleEffectState
> {
  timeout: any;
  constructor(props: RippleEffectProps) {
    super(props);
    this.state = {
      active: false,
      rippleStyles: {},
    };
  }

  startAnimation = (e: React.MouseEvent<HTMLDivElement>) => {
    const { actionFirst, circled, rounded } = this.props;
    const boundRect = e.currentTarget.getBoundingClientRect();
    const size =
      boundRect.width > boundRect.height ? boundRect.width : boundRect.height;
    const spreadFromCenter = circled || rounded;
    const width = spreadFromCenter ? boundRect.width : size;
    const height = spreadFromCenter ? boundRect.height : size;

    if (actionFirst && this.props.onClick) {
      this.props.onClick();
    }

    this.setState({
      active: true,
      rippleStyles: {
        left: spreadFromCenter
          ? (boundRect.right - boundRect.left) / 2 - width / 2
          : e.clientX - boundRect.left - width / 2,
        top: spreadFromCenter
          ? (boundRect.bottom - boundRect.top) / 2 - height / 2
          : e.clientY - boundRect.top - height / 2,
        width,
        height,
      },
    });

    this.timeout = setTimeout(() => {
      this.setState({
        active: false,
        rippleStyles: {},
      });
      this.timeout = undefined;
      if (!actionFirst && this.props.onClick) {
        this.props.onClick();
      }
    }, 300);
  };

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  render() {
    const {
      disabled,
      children,
      inverse,
      circled,
      rounded,
      className,
      actionFirst,
      inline,
      ...rest
    } = this.props;
    return (
      <div
        {...rest}
        className={cn(
          "RippleEffect",
          {
            "RippleEffect--active": this.state.active,
            "RippleEffect--disabled": disabled,
            "RippleEffect--inverse": inverse,
            "RippleEffect--circled": circled,
            "RippleEffect--rounded": rounded,
            "RippleEffect--inline": inline,
          },
          className
        )}
        onClick={(e) => {
          e.stopPropagation();
          !disabled && this.startAnimation(e);
        }}
      >
        {children}
        <div className="RippleEffect__ripple" style={this.state.rippleStyles} />
      </div>
    );
  }
}
