import * as React from "react";
import cn from "classnames";

import "./DoubleBounce.scss";

interface DoubleBounceProps {
  size?: string;
  isPrimary?: boolean;
}

export const DoubleBounce = ({ size, isPrimary }: DoubleBounceProps) => {
  const styles: React.CSSProperties = {
    width: size || "4rem",
    height: size || "4rem",
  };

  return (
    <div
      className={cn("DoubleBounce", { "DoubleBounce--primary": isPrimary })}
      style={styles}
    >
      <div className="DoubleBounce__bounce1" />
      <div className="DoubleBounce__bounce2" />
    </div>
  );
};
