const textStyles = {
  h1: {
    fontFamily: "main",
    fontSize: ["2xl", "4xl"],
    letterSpacing: 0,
    color: "grey.400",
    fontWeight: "bold",
    lineHeight: "normal",
  },
  h2: {
    fontFamily: "main",
    fontSize: ["xl", "3xl"],
    letterSpacing: 0,
    color: "grey.400",
    fontWeight: "bold",
    lineHeight: "normal",
  },
  h3: {
    fontFamily: "main",
    fontSize: ["md", "xl"],
    letterSpacing: "0.33px",
    color: "grey.400",
    fontWeight: "bold",
    lineHeight: "normal",
  },
  h6: {
    fontFamily: "main",
    fontSize: ["sm", "md"],
    letterSpacing: "0.5px",
    color: "grey.400",
    fontWeight: "bold",
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  p: {
    fontFamily: "main",
    fontSize: ["md", "xl"],
    letterSpacing: "0",
    color: "grey.400",
    fontWeight: "normal",
    lineHeight: "normal",
  },
  label: {
    fontFamily: "main",
    fontSize: ["xs", "sm"],
    letterSpacing: "0",
    color: "grey.400",
    fontWeight: 400,
    lineHeight: "normal",
  },
};

export default textStyles;
