const colors = {
  denim: {
    50: "#e7f3ff",
    100: "#c9d7e9",
    200: "#a9bcd5",
    300: "#88a1c3",
    400: "#6786b1",
    500: "#4e6d98",
    600: "#3c5577",
    700: "#2a3c56",
    800: "#172436",
    900: "#040c18",
  },
  grey: {
    50: "#e3f6fc",
    100: "#cedbe3",
    200: "#b4c1cb",
    300: "#97a9b4",
    400: "#7b909d",
    500: "#627684",
    600: "#4b5c68",
    700: "#33424b",
    800: "#1d2830",
    900: "#010e18",
  },
  tomato: {
    50: "#ffe5e4",
    100: "#fcbab9",
    200: "#f38e8d",
    300: "#ec615f",
    400: "#e63533",
    500: "#cc1c19",
    600: "#a01313",
    700: "#720c0c",
    800: "#470505",
    900: "#1f0000",
  },
  red: {
    50: "#ffe1e1",
    100: "#ffb1b1",
    200: "#ff7f7f",
    300: "#ff4c4c",
    400: "#ff1a1a",
    500: "#e60000",
    600: "#b40000",
    700: "#810000",
    800: "#500000",
    900: "#210000",
  },
};

export default colors;
