import React, { useEffect } from "react";
import { Text, Box } from "@chakra-ui/react";
import { Layout } from "components/shared";
import { HomeRoute } from "lib/routes";
import { useHistory } from "react-router-dom";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";

export const Newsletters = () => {
  const history = useHistory();

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowNewsLettersPage, {});
  }, []);

  return (
    <Layout title="Newsletters" onGoBack={() => history.push(HomeRoute)}>
      <Box d="flex" mb={[5, 7]} width="100%">
        <Text textStyle="h3" color="denim.600">
          This is a newsletter page
        </Text>
      </Box>
    </Layout>
  );
};
