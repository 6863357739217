import { ApiError } from "models/auth";
import { parse } from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useShouldShowPrompt } from "hooks/app";

export async function transformResponse(response: Response) {
  let data;
  try {
    if (response.ok) {
      data = await response.json();
      return data;
    } else {
      throw new ApiError(response.status, null);
    }
  } catch (e) {
    data = null;
  }
}

export function hideSplashScreen() {
  document.body.querySelector<HTMLDivElement>("#splash")!.style.display =
    "none";
}

export function isMobile(): boolean {
  return /Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);
}

export function useQuery<T>() {
  const search = useLocation().search;

  const params = useMemo(() => parse(search), [search]);
  return params as unknown as T;
}

export const getBankIdUrl = () => {
  return `bankid:///?redirect=null`;
};

const visitedPagesKey = "pages-visited";

function getVisitedPages(): { [path: string]: boolean } {
  const item = localStorage.getItem(visitedPagesKey);
  if (!item) {
    return {};
  }

  try {
    return JSON.parse(item);
  } catch (e) {
    return {};
  }
}

export function setVisitedPage(path: string) {
  const visitedPages = getVisitedPages();

  visitedPages[path] = true;

  localStorage.setItem(visitedPagesKey, JSON.stringify(visitedPages));
}

export function isFirstPageVisit(path: string) {
  return !getVisitedPages()[path];
}

export const getPerformanceDates = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  return {
    month: date.toLocaleDateString("sv", {
      month: "short",
    }),
    year: date.getFullYear().toString(),
  };
};

export const formatSignedValue = (val: number, fractionDigits = 1) => {
  if (val) {
    return val?.toLocaleString("sv", {
      minimumFractionDigits: 1,
      maximumFractionDigits: fractionDigits,
      signDisplay: "always",
    });
  } else {
    return 0;
  }
};

export function isPPM(pensionInstitute: string): boolean {
  return (
    pensionInstitute === "Pensionsmyndigheten" ||
    pensionInstitute === "PPM Pensionsmyndigheten"
  );
}

export function ppmInstituteForQuery(pensionInstitute: string): string {
  if (
    pensionInstitute === "Pensionsmyndigheten" ||
    pensionInstitute === "PPM Pensionsmyndigheten"
  ) {
    return "Pensionsmyndigheten";
  } else {
    return pensionInstitute;
  }
}
export function ppmInstituteForNames(pensionInstitute: string): string {
  if (
    pensionInstitute === "Pensionsmyndigheten" ||
    pensionInstitute === "PPM Pensionsmyndigheten"
  ) {
    return "PPM Pensionsmyndigheten";
  } else {
    return pensionInstitute;
  }
}

export function returnLinkValue(link: string): string {
  if (link.startsWith("http://") || link.startsWith("https://")) {
    return link;
  } else {
    return `https://${link}`;
  }
}

export function setTemporaryState(
  id: string,
  type: string,
  value: string
): void {
  localStorage.setItem(
    `temporaryState${String(id)}${String(type)}`,
    `${value}`
  );
}
export function getTemporaryState(id: string, type: string): string {
  const value: string =
    localStorage.getItem(`temporaryState${String(id)}${String(type)}`) || " ";
  return value;
}
export function removeTemporaryState(id: string, type: string): void {
  localStorage.removeItem(`temporaryState${String(id)}${String(type)}`);
}

export function localStorageSetLogin(): void {
  localStorage.setItem("loggedIn", "true");
}
export function localStorageGetLogin(): boolean {
  return !!localStorage.getItem("loggedIn");
}
export function localStorageRemoveLogin(): void {
  localStorage.removeItem("loggedIn");
}

export const iosInstallPromptedAt = "iosInstallPromptedAt";
export const webInstallPromptedAt = "webInstallPromptedAt";

export const isIOS = (): boolean => {
  // @ts-ignore
  if (navigator.standalone) {
    //user has already installed the app
    return false;
  }
  const ua = window.navigator.userAgent;
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);
  return isIPad || isIPhone;
};

const introWatchedKey = "introWatched";
export const setIntroWatched = () =>
  localStorage.setItem(introWatchedKey, "true");
export const getIntroWatched = () => !!localStorage.getItem(introWatchedKey);
export const clearIntroWatched = () => localStorage.removeItem(introWatchedKey);
