export interface InsuranceShell {
  _id: string;
  pension_institute: string;
  insurance_type: string;
  show_to_user: boolean;
  link?: string;
}

export enum RecommendationType {
  Sinful = "sinful",
  Sustainable = "sustainable",
}

export interface FundRecommendation {
  classification: RecommendationType;
  created_by: string;
  created_date: string;
  funds: {
    _id: string;
    allocation: number;
    fund_in_shell: {
      _id: string;
      id_in_shell?: string;
      fund: {
        _id: string;
        name: string;
      };
    };
  }[];
  insurance_shell: string;
  rationale: string;
  risk_level: string;
  _id: string;
}

export interface RecommendationPerformance {
  monthlyChangesWithIndex: {
    //"2020-10": { percent: 1.75; index: 101.75 };
    [yearAndMonth: string]: { percent: number; index: number };
  };
  weightedMetrics: {
    ytdReturn: number;
    oneMonthReturn: number;
    threeMonthReturn: number;
    sixMonthReturn: number;
    oneYearReturn: number;
    threeYearReturn: number;
    fiveYearReturn: number;
    morningstarSustainability: number;
    morningstarRating: number;
    ourRating: number;
  };
  overall: number;
  total: {
    month: number;
    year: number;
  };
}
export interface ShellRecommendationPerformance {
  sinful?: RecommendationPerformance;
  sustainable?: RecommendationPerformance;
  allFundsAverages: {
    combinedRating: number;
    morningstarSustainability: number;
    morningstarRating: number;
    ourRating: number;
  };
}
