import { Button, Link, Text } from "@chakra-ui/react";
import { Layout } from "components/shared";
import { SummaryComponentProps } from "models/insurance-purchase";
import React, { FC } from "react";

export const LifeInsuranceSummary: FC<SummaryComponentProps> = ({
  isSaving,
  coverage,
  price,
  onPrev,
  onNext,
}) => {
  return (
    <Layout title="Din Livförsäkring" onGoBack={onPrev}>
      <Text textStyle="h6" as="h6" mb={[2]}>
        Teckna försäkringen
      </Text>
      <Text textStyle="p" mb={[6]}>
        Du har valt att försäkra ett belopp om{" "}
        <strong>{coverage.toLocaleString("sv")} kr.</strong>
        <br />
        Din månadskostnad för försäkringen blir{" "}
        <strong>{price.toLocaleString("sv")} kr.</strong>
        <br />
        <br />
        Försäkringen innehåller vissa undantag som du kan läsa om i
        förköpsinformationen. Försäkringsvillkor och förköpsinformation finns på{" "}
        <Link
          target="_blank"
          href="https://www.pensionskraft.se/livforsakring/"
          rel="noreferrer"
          fontWeight="semibold"
          textDecoration="underline"
        >
          www.pensionskraft.se/livforsakring
        </Link>
        .
      </Text>
      <Text textStyle="h6" as="h6" mb={[2]}>
        Genom att signera bekräftar jag att jag:
      </Text>
      <Text textStyle="p" mb={[6]}>
        - tagit del av och förstått förköpsinformationen;
        <br />
        - är folkbokförd i Sverige;
        <br />
        - har fyllt 18 men inte 64 år (skyddet gäller tills fyllda 65 år);
        <br />
        - är fullt frisk och fullt arbetsför, inte har kännedom om allvarlig
        sjukdom, sjukdomstillstånd, skada eller kommande sjukskrivning;
        <br />
        - under de senaste 12 månaderna inte haft någon kontakt med hälso- och
        sjukvården eller fått medicinering för en kronisk eller återkommande
        sjukdom;
        <br />
        - inte har någon sjukhusvistelse inplanerad och/eller inte har ett
        läkar-/specialistbesök inbokat för odiagnostiserat tillstånd eller
        symptom samt;
        <br />
        - aldrig fått behandling för: stroke/TIA någon form av cancer, leukemi
        eller tumör, någon form av transplantation, hjärt-/kärlsjukdom,
        immunbristsjukdom, nedsatt njurfunktion eller diabetes.
        <br />
        <br />
        Det är viktigt att du uppfyller ovan annars kan försäkringsersättning
        utebli.
      </Text>
      <Text textStyle="h6" as="h6" mb={[2]}>
        Ångerrätt
      </Text>
      <Text textStyle="p" mb={[6]}>
        Du kan kontakta Pensionskraft inom 30 dagar efter att du fått hem
        försäkringsbeskedet, om du vill ångra tecknandet av försäkringen. Du kan
        därefter säga upp försäkringen när du vill. Ytterligare information om
        ångerrätt och uppsägning finner du i förköpsinformationen.
      </Text>

      <Button colorScheme="tomato" onClick={onNext} isLoading={isSaving}>
        Teckna försäkringen
      </Button>
    </Layout>
  );
};
