import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { App } from "components/app";

import * as swRegistration from "./serviceWorkerRegistration";

import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = process.env.REACT_APP_BUGSNAG_KEY
  ? Bugsnag.getPlugin("react")!.createErrorBoundary(React)
  : React.Fragment;

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

swRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
