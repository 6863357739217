import { swedishBanks } from "assets/swedishBanks";
import { string } from "yup";

const requiredFieldText = "Det här fältet behöver fyllas i";

export const RequiredStringSchema = string().required(requiredFieldText);
export const PersonNumberSchema = string().trim().required(requiredFieldText);
export const EmailSchema = string()
  .required("Ange din email")
  .email("Kontrollera din email-adress!");
export const SwedishBankSchema = string()
  .oneOf(swedishBanks)
  .required(requiredFieldText);
