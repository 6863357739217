import colors from "./colors";
import fonts from "./fonts";
import fontSizes from "./fontSizes";
import fontWeights from "./fontWeights";
import shadows from "./shadows";
import textStyles from "./textStyles";

const foundations = {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  shadows,
  textStyles,
};

export default foundations;
