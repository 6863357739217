import React from "react";
import { Card } from "components/shared";
import { Image, Text } from "@chakra-ui/react";
import { InsurancesBg } from "assets/images";
import { useHistory, Link } from "react-router-dom";
import { NewslettersRoute } from "lib/routes";

export const NewsletterCard = () => {
  const history = useHistory();
  return (
    <Card
      cursor="pointer"
      height={["210px", "300px"]}
      position="relative"
      overflow="hidden"
      onClick={() =>
        window.open("https://www.pensionskraft.se/publikation/", "_blank")
      }
    >
      <Text textStyle="h1" textAlign="center" mt={[4, 6]}>
        Artiklar om pension
      </Text>
      <Text
        textStyle="p"
        textAlign="center"
        width="70%"
        mt={[2, 4]}
        mx="auto"
        as="h1"
      >
        Här hittar du artiklar om pension och ekonomi.
      </Text>
      <Image
        position="absolute"
        left="50%"
        bottom="-3%"
        transform="translateX(-50%)"
        src={InsurancesBg}
        zIndex={-1}
        maxW="initial"
        width="90%"
        height="90%"
        userSelect="none"
      ></Image>
    </Card>
  );
};
