import { BlockerScreen, BlockerScreenType } from "components/shared";

import { useLoginWaitingPageData } from "hooks/pages/login";
import { isMobile } from "lib/utils";

export const LoginWaiting = () => {
  useLoginWaitingPageData();

  return (
    <BlockerScreen
      type={BlockerScreenType.Progress}
      title={`${isMobile() ? "Startar" : "Starta"} din BankID-app`}
    ></BlockerScreen>
  );
};
