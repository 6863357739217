import { createIcon } from "@chakra-ui/react";

const Error = createIcon({
  displayName: "Error",
  viewBox: "0 0 70 71",
  path: (
    <g fill="currentColor" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M35 .497c-19.31 0-35 15.69-35 35 0 19.309 15.69 35 35 35 19.31 0 35-15.691 35-35 0-19.31-15.69-35-35-35zm0 3.5c17.418 0 31.5 14.082 31.5 31.5s-14.082 31.5-31.5 31.5-31.5-14.082-31.5-31.5 14.082-31.5 31.5-31.5z"
      />
      <path d="M48.03 19.993L19.591 48.43l2.474 2.475 28.438-28.437z" />
      <path d="M21.916 19.74l-2.475 2.475 28.643 28.642 2.475-2.474z" />
    </g>
  ),
  defaultProps: {
    color: "tomato.400",
  },
});

export default Error;
