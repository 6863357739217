import { LoginStatus } from "api/endpoints";
import { useApiSWR } from "hooks/api";
import { cache } from "swr";
import { useLoginRequest } from "hooks/auth";
import { useHideSplash } from "hooks/layout";
import {
  ErrorRoute,
  HomeRoute,
  LoginRoute,
  LoginWaitingRoute,
} from "lib/routes";
import {
  getBankIdUrl,
  isMobile,
  localStorageRemoveLogin,
  localStorageSetLogin,
} from "lib/utils";
import { LoginStatusResponse } from "models/auth";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { HeapEventNames, identify, trackHeapEvent } from "lib/analytics";

export interface LoginFormValues {
  personNumber: string;
  savePersonNumber?: boolean;
}

export const personNumberKey = "loginPersonNumber";
export const loginStartedAtKey = "loginStartedAt";

const getLoginStartedTime = () => {
  const item = localStorage.getItem(loginStartedAtKey);
  return item ? new Date(item) : null;
};

export const useLoginPageData = () => {
  useHideSplash();

  const { isLoading, loginRequest } = useLoginRequest();
  const history = useHistory();

  const onSubmit = useCallback(
    ({ personNumber, savePersonNumber }: LoginFormValues) => {
      if (savePersonNumber) {
        localStorage.setItem(personNumberKey, personNumber);
      } else {
        localStorage.removeItem(personNumberKey);
      }

      const timeout = setTimeout(() => {
        if (isMobile()) {
          window.location.href = getBankIdUrl();
        }
        // give server some time to process request in case of error
      }, 2000);

      loginRequest(
        {
          username: personNumber,
          password: "bankid",
        },
        {
          onSuccess: (resp) => {
            identify(resp._id);
            localStorageSetLogin();
            trackHeapEvent(HeapEventNames.LoggedInUsingStandardWay, {
              userId: resp._id,
            });
            clearTimeout(timeout);
          },
        }
        // { onError: () => clearTimeout(timeout) }
      );
      localStorage.setItem(loginStartedAtKey, new Date().toString());

      history.push(LoginWaitingRoute);
    },
    [loginRequest, history]
  );

  return {
    onSubmit,
    isLoading,
  };
};

export const useLoginWaitingPageData = () => {
  useHideSplash();

  const [loginStartedAt] = useState(getLoginStartedTime());
  const previousAttemptsReceived = useRef(0);

  const history = useHistory();

  const { data } = useApiSWR<LoginStatusResponse>(LoginStatus, {
    refreshInterval: 1000,
  });

  useEffect(() => {
    if (!data) {
      localStorageRemoveLogin();
      return;
    }

    if (!loginStartedAt) {
      localStorageRemoveLogin();
      history.push(LoginRoute);
      return;
    }

    // this is response from previous login attempt we need to skip it and wait for new result
    const isPreviousAttempt =
      new Date(data.started_at).getTime() < loginStartedAt.getTime();
    if (isPreviousAttempt && !previousAttemptsReceived.current) {
      previousAttemptsReceived.current += 1;
      return;
    }

    // login finished
    if (!data.pending && !!data.started_at && !!data.ended_at) {
      if (data.success) {
        cache.clear();
        localStorageSetLogin();
        history.push(HomeRoute);
      } else if (data.success === false) {
        localStorageRemoveLogin();
        history.push(
          ErrorRoute.format({ message: data.message, redirectUrl: LoginRoute })
        );
      } else {
        localStorageRemoveLogin();
        history.push(
          ErrorRoute.format({ redirectUrl: LoginRoute, reload: true })
        );
      }
    }
  }, [loginStartedAt, history, data]);
};
