import { mode } from "@chakra-ui/theme-tools";

type Dict = Record<string, any>;

function baseStyleTab() {
  return {
    fontWeight: "bold",
    color: "grey.400",
    _focus: {
      boxShadow: "nonne",
    },
  };
}

const baseStyleTabpanel = { p: 8 };

const baseStyle = () => ({
  tab: baseStyleTab(),
  tabpanel: baseStyleTabpanel,
});

const sizes = {
  md: {
    tab: {
      fontSize: ["1rem", "1.125rem"],
      p: "1.125rem",
    },
  },
};

function variantLine(props: Dict) {
  const { orientation } = props;
  const isVertical = orientation === "vertical";
  const borderProp = orientation === "vertical" ? "borderLeft" : "borderBottom";
  const marginProp = isVertical ? "ml" : "mb";

  return {
    tablist: {
      [borderProp]: "3px solid",
      borderColor: "inherit",
    },
    tab: {
      [borderProp]: "3px solid",
      borderColor: "transparent",
      [marginProp]: "-3px",
      _selected: {
        color: "denim.600",
        borderColor: "currentColor",
      },
      _active: {
        bg: mode("gray.200", "whiteAlpha.300")(props),
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
    },
  };
}

const variants = {
  line: variantLine,
};

const defaultProps = {
  size: "md",
  variant: "line",
  colorScheme: "denim",
};

const Tabs = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default Tabs;
