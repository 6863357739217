import {
  Skeleton,
  Text,
  Stack,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  HStack,
  Button,
} from "@chakra-ui/react";
import { Layout, ListItem, HelpButton } from "components/shared";
import { usePensionInstitutes } from "hooks/funds";
import { usePPMConsent } from "hooks/user";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";
import {
  HomeRoute,
  InsuranceShellsRoute,
  ShellRecommendationsRoute,
} from "lib/routes";
import { isPPM } from "lib/utils";
import { RecommendationType } from "models/funds";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const PensionInstitutes = () => {
  const { isLoading, pensionInstitutes } = usePensionInstitutes();
  const history = useHistory();
  // const {
  //   isConsentGiven,
  //   isLoading: isSavingConsent,
  //   onGiveConsent,
  // } = usePPMConsent();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const goToPPMShell = useCallback(
    () =>
      history.push(
        ShellRecommendationsRoute.format({
          pensionInstitute: "PPM Pensionsmyndigheten",
          insuranceShell: "Premiepension",
          recommendationType: RecommendationType.Sinful,
        })
      ),
    [history]
  );

  const onSelectInstitute = useCallback(
    (inst: string) => {
      // isPPM(inst)
      //   ? isConsentGiven
      //     ? goToPPMShell()
      //     : onOpen()
      //   : history.push(InsuranceShellsRoute.format({ pensionInstitute: inst }));
      isPPM(inst)
        ? goToPPMShell()
        : history.push(InsuranceShellsRoute.format({ pensionInstitute: inst }));
    },
    [
      history,
      // isConsentGiven,
      onOpen,
      goToPPMShell,
    ]
  );

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowPensionInstitutesPage, {});
  }, []);

  return (
    <>
      <Layout title="Pensionsbolag" onGoBack={() => history.push(HomeRoute)}>
        <Text textStyle="p" textAlign="center" mb={[10]}>
          Välj det pensionsbolag som förvaltar dina pengar. <br />
          Du kan ha flera.
        </Text>

        <Stack spacing={[3, 4]} mb={20} flexGrow={1}>
          {isLoading ? (
            <>
              <Skeleton height={[12]} />
              <Skeleton height={[12]} />
              <Skeleton height={[12]} />
              <Skeleton height={[12]} />
              <Skeleton height={[12]} />
            </>
          ) : (
            pensionInstitutes.map((inst) => (
              <ListItem
                key={inst}
                label={inst}
                onClick={() => onSelectInstitute(inst)}
              />
            ))
          )}
        </Stack>

        <HelpButton
          question="Hur vet jag vilket pensionsbolag jag har?"
          modalContent={
            <Text textStyle="p" mb={4}>
              Du kan ha ditt pensionskapital hos flera olika pensionsbolag. Om
              du inte vet vilka just du har, så hittar du det enkelt genom att{" "}
              <Link
                target="_blank"
                href="https://www.minpension.se/"
                rel="noreferrer"
                fontWeight="semibold"
                textDecoration="underline"
              >
                logga in på MinPension.
              </Link>{" "}
              Där får du en sammanställning på vilka pensionbolag just du har.
            </Text>
          }
        />
      </Layout>
      {/* <Modal isOpen={isOpen} onClose={() => {}} isCentered size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Premiepension</ModalHeader>
          <ModalBody>
            <Text textStyle="p" mb={[6, 8]}>
              Du kan även få råd och rekommendationer för din premiepension.
              Dest kostar inget extra.
            </Text>
            <HStack w="100%" spacing={4}>
              <Button
                type="submit"
                colorScheme="grey"
                position="relative"
                disabled={isSavingConsent}
                onClick={onClose}
              >
                Nej
              </Button>
              <Button
                colorScheme="tomato"
                position="relative"
                disabled={isSavingConsent}
                mt={[6, 8]}
                onClick={() =>
                  onGiveConsent({
                    onSuccess: goToPPMShell,
                  })
                }
              >
                Ja, Tack.
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};
