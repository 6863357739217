import textStyles from "../foundations/textStyles";

const error = {
  baseStyle: {
    text: {
      ...textStyles.label,
      opacity: 1,
      color: "red.500",
    },
  },
};

export default error;
