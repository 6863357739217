import { useState, useEffect } from "react";

import moment from "moment";
import {
  iosInstallPromptedAt,
  webInstallPromptedAt,
  isIOS,
} from "../lib/utils";

const getInstallPromptLastSeenAt = (promptName: string): string =>
  localStorage.getItem(promptName) || "";

const setInstallPromptSeenToday = (promptName: string): void => {
  const today = moment().toISOString();
  localStorage.setItem(promptName, today);
};

function getUserShouldBePromptedToInstall(
  promptName: string,
  daysToWaitBeforePromptingAgain: number
): boolean {
  const lastPrompt = moment(getInstallPromptLastSeenAt(promptName));
  const daysSinceLastPrompt = moment().diff(lastPrompt, "days");
  return (
    isNaN(daysSinceLastPrompt) ||
    daysSinceLastPrompt > daysToWaitBeforePromptingAgain
  );
}

export const useShouldShowPrompt = (
  promptName: string,
  daysToWaitBeforePromptingAgain: number = 1
): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] =
    useState(
      getUserShouldBePromptedToInstall(
        promptName,
        daysToWaitBeforePromptingAgain
      )
    );

  const handleUserSeeingInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday(promptName);
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};

export const useWebInstallPrompt = (): [any, () => void, () => void] => {
  const [installPromptEvent, setInstallPromptEvent] = useState<any>(null);
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] =
    useShouldShowPrompt(webInstallPromptedAt, 28);

  useEffect(() => {
    const beforeInstallPromptHandler = (event: any) => {
      event.preventDefault();

      // check if user has already been asked
      if (userShouldBePromptedToInstall) {
        // store the event for later use
        setInstallPromptEvent(event);
      }
    };
    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    return () =>
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
  }, [userShouldBePromptedToInstall]);

  const handleInstallDeclined = () => {
    handleUserSeeingInstallPrompt();
    setInstallPromptEvent(null);
  };

  const handleInstallAccepted = () => {
    if (installPromptEvent) {
      // show native prompt
      installPromptEvent.prompt();

      // decide what to do after the user chooses
      installPromptEvent.userChoice.then((choice: any) => {
        // if the user declined, we don't want to show the prompt again
        if (choice.outcome !== "accepted") {
          handleUserSeeingInstallPrompt();
        }
        if (choice.outcome === "accepted") {
          handleUserSeeingInstallPrompt();
          setInstallPromptEvent(null);
        }
      });
    }
  };
  return [installPromptEvent, handleInstallDeclined, handleInstallAccepted];
};

export const useIosInstallPrompt = (): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] =
    useShouldShowPrompt(iosInstallPromptedAt, 28);

  return [
    isIOS() && userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ];
};
