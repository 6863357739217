import { createIcon } from "@chakra-ui/react";

// const ArrowUp = createIcon({
//   displayName: "ArrowUp",
//   viewBox: "0 0 9 16",
//   path: (
//     <g fill="none" fillRule="evenodd">
//       <g fill="currentColor">
//         <g>
//           <g>
//             <g>
//               <path
//                 d="M9.609 6.781c-.505.541-1.325.541-1.831 0L6.766 5.698v9.927c0 .476-.227.896-.571 1.143-.204.146-.45.231-.715.231-.71 0-1.286-.615-1.286-1.374V5.72l-.992 1.061c-.505.541-1.325.541-1.83 0-.506-.54-.506-1.417 0-1.958l3.203-3.426c.505-.541 1.325-.541 1.831 0l3.203 3.426c.505.541.505 1.418 0 1.958z"
//                 transform="translate(-178 -339) translate(34 276) translate(143 57) translate(0 5)"
//               />
//             </g>
//           </g>
//         </g>
//       </g>
//     </g>
//   ),
//   defaultProps: {
//     color: "#A7E521",
//   },
// });

// export default ArrowUp;
const ArrowUp = createIcon({
  displayName: "ArrowUp",
  viewBox: "0 0 55 55",
  path: (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 122.88"
    >
      {" "}
      <path
        fill="currentColor"
        fillRule="evenodd"
        style={{ marginRight: "10px" }}
        d="M61.44,0A61.51,61.51,0,1,1,18,18,61.25,61.25,0,0,1,61.44,0Zm5,45.27A7.23,7.23,0,1,0,56.14,35.13L35,56.57a7.24,7.24,0,0,0,0,10.15l20.71,21A7.23,7.23,0,1,0,66.06,77.62l-8.73-8.87,24.86-.15a7.24,7.24,0,1,0-.13-14.47l-24.44.14,8.84-9Z"
        transform="rotate(115 5 11.5) translate(-37 -122)"
      />
    </svg>
  ),
  defaultProps: {
    color: "#A7E521",
  },
});

export default ArrowUp;
