import { Box, Text, Skeleton } from "@chakra-ui/react";
import { FundRecommendation, RecommendationType } from "models/funds";
import { RecommendedFunds } from "components/shared";
import * as Icons from "assets/icons";
import { PortfolioInfoStarItemSmall } from "components/portfolio-info";
import { usePortfolioInfoData } from "hooks/pages/portfolioInfo";

interface PortfolioProps {
  recommendations?: FundRecommendation;
  onShowInfo(): void;
}

export const Portfolio = ({ recommendations, onShowInfo }: PortfolioProps) => {
  const { isLoading, isSustainable, pensionInstitute, insuranceShell, rating } =
    usePortfolioInfoData();

  return (
    <Box d="flex" flexDir="column" alignItems="stretch" marginBottom={[4]}>
      <Text textStyle="p" mt={[4, 6]} mb={[8, 10]} textAlign="center">
        {recommendations?.classification === RecommendationType.Sinful
          ? "För Standardportföljen väljer vi ut de fyra fonder som har lägst avgifter, högst fondbetyg och där utvecklingen varit bättre än övriga fonder."
          : "För den Hållbara portföljen prioriterar vi fonder med höga hållbarhetsbetyg. Sedan väljs de fyra fonder som har lägst avgifter, högst fondbetyg och där utvecklingen varit bättre än övriga fonder."}
      </Text>
      <Box mb={[10, 12]}>
        {recommendations ? (
          <RecommendedFunds recommendations={recommendations} />
        ) : (
          <Skeleton minH="200px" isLoaded={false} />
        )}
      </Box>
      <Box>
        {isSustainable && (
          <PortfolioInfoStarItemSmall
            isLoading={isLoading}
            pensionInstitute={pensionInstitute}
            insuranceShell={insuranceShell}
            title="Hållbarhet"
            portfolioRating={rating.sustainability.portfolio}
            averageRating={rating.sustainability.allFundsAverage}
          />
        )}
        {!isSustainable && (
          <PortfolioInfoStarItemSmall
            isLoading={isLoading}
            pensionInstitute={pensionInstitute}
            insuranceShell={insuranceShell}
            title="Fondbetyg"
            portfolioRating={rating.standard.portfolio}
            averageRating={rating.standard.allFundsAverage}
          />
        )}
      </Box>
      <Box
        mt={5}
        d="flex"
        alignItems="center"
        alignSelf="center"
        cursor="pointer"
        onClick={onShowInfo}
      >
        <Icons.BarChart w="21px" h="16px" mr={[3, 4]} />
        <Text textStyle="p" color="denim.600" fontWeight="bold">
          Mer info om portföljen
        </Text>
      </Box>
    </Box>
  );
};
