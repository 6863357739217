import { createIcon } from "@chakra-ui/react";

const Profile = createIcon({
  displayName: "Profile",
  viewBox: "0 0 40 40",
  path: (
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20 20-8.954 20-20S31.046 0 20 0zm0 37.5c-3.346 0-6.62-.966-9.43-2.781v-2.615a9.43 9.43 0 1 1 18.86 0v2.615A17.379 17.379 0 0 1 20 37.5zm0-17.507a4.995 4.995 0 1 1 0-9.99 4.995 4.995 0 0 1 0 9.99zm11.93 12.774v-.663a11.93 11.93 0 0 0-7.528-11.066 7.495 7.495 0 1 0-8.804 0A11.93 11.93 0 0 0 8.07 32.104v.663a17.5 17.5 0 1 1 23.86 0z"
      />
    </g>
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default Profile;
