import React from "react";
import { Spinner, Box } from "@chakra-ui/react";
import "animate.css";

const Loading = (props: any) => {
  return (
    <Box
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(17,17,17,0.5)",
        left: 0,
        top: 0,
        zIndex: 221,
        animation: props.loading ? "fadeIn 300ms" : "fadeOut 300ms",
      }}
    >
      <Spinner
        color="white"
        speed="0.5s"
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        zIndex={222}
        size="lg"
      />
    </Box>
  );
};
export { Loading };
