import { createIcon } from "@chakra-ui/react";

const Heart = createIcon({
  displayName: "Heart",
  viewBox: "0 0 18 16",
  path: (
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <g>
          <path
            d="M13.333 0C11.407 0 9.511 1.244 8.89 2.963 8.267 1.244 6.37 0 4.444 0 1.99 0 0 1.99 0 4.444 0 8.296 3.26 12.148 8.889 16c5.63-3.852 8.889-7.704 8.889-11.556C17.778 1.99 15.788 0 13.333 0z"
            transform="translate(-323 -64) translate(323 64)"
          />
        </g>
      </g>
    </g>
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default Heart;
