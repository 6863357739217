export enum LinkActions {
  ShowMessage = "trygga-app-show-messages",
  ShowMain = "trygga-app-show-main",
  ShowStandaloneLifeInsuranceFlow = "trygga-app-show-standalone-life-insurance",
  ShowStandaloneSafetyInsuranceFlow = "trygga-app-show-standalone-safety-insurance",
  ShowStandaloneLifeInsuranceUpsellFlow = "trygga-app-show-standalone-life-insurance-upsell",
  ShowStandaloneSafetyInsuranceUpsellFlow = "trygga-app-show-standalone-safety-insurance-upsell",
}

export enum MessageCodes {
  FundPerformance = "view-fund-performance",
}

export class ApiError extends Error {
  constructor(public status: number, public info: any) {
    super();
  }
}

export interface AccessCodeResponse {
  user: {
    _id: string;
    person_number: string;
  };
  link_action: LinkActions;
  payload: MessageCodes;
}

export interface LoginRequestBody {
  username: string;
  password: string;
}

export interface LoginResponse {
  _id: string;
}

export interface WhoAmIResponse {
  _id: string;
}

export interface LoginStatusBase {
  pending: boolean;
  started_at: string;
  redirect?: string;
}

export interface LoginStatusPending extends LoginStatusBase {
  pending: true;
}

export interface LoginStatusSuccess extends LoginStatusBase {
  pending: false;
  success: true;
  ended_at: string;
}

export interface LoginStatusError extends LoginStatusBase {
  pending: false;
  success: false;
  ended_at: string;
  message: string;
}

export type LoginStatusResponse =
  | LoginStatusPending
  | LoginStatusError
  | LoginStatusSuccess;
