import { ReactNode } from "react";
import { Divider, Text, Box } from "@chakra-ui/react";
import { MotionBox } from "./MotionBox";

export const Card: typeof Box = (props) => {
  return (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      borderRadius={["6px"]}
      boxShadow="default"
      width="100%"
      {...(props as any)}
    />
  );
};

interface CardTitleProps {
  children: ReactNode;
}

export const CardTitle = ({ children }: CardTitleProps) => {
  return (
    <>
      <Box px={[7, 10]} py={[4, 6]}>
        <Text textStyle="h6" as="h6">
          {children}
        </Text>
      </Box>
      <Divider />
    </>
  );
};

interface CardContentProps {
  children: ReactNode;
}

export const CardContent = ({ children }: CardContentProps) => {
  return (
    <Box px={[7, 10]} py={[4, 6]}>
      {children}
    </Box>
  );
};
