import { createIcon } from "@chakra-ui/react";

const ArrowLeft = createIcon({
  displayName: "ArrowLeft",
  viewBox: "0 0 23 18",
  path: (
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor" strokeWidth="2">
        <g>
          <path
            d="M0 7.999L20.571 7.999M8 0L0 8 8 16"
            transform="translate(-32 -63) translate(33 64)"
          />
        </g>
      </g>
    </g>
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default ArrowLeft;
