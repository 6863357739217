import { useCurrentUser, useUpdateUserRequest } from "hooks/user";
import { ErrorRoute, SettingsRoute } from "lib/routes";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

export interface SettingsFormValues {
  firstName: string;
  lastName: string;
  personNumber: string;
  email: string;
  phone: string;
}

export const useSettingsPageData = () => {
  const { isLoading, user, mutate } = useCurrentUser();
  const { isLoading: isSaving, updateUserRequest } = useUpdateUserRequest(
    user?._id!
  );

  const history = useHistory();

  const onSubmit = useCallback(
    ({ firstName, lastName, phone, email }: SettingsFormValues) => {
      updateUserRequest(
        {
          name: {
            first: firstName,
            last: lastName,
          },
          email,
          phone: {
            mobile: phone,
          },
        },
        {
          onSuccess: (resp) => mutate(resp, false),
          onError: () =>
            history.push(ErrorRoute.format({ redirectUrl: SettingsRoute })),
        }
      );
    },
    [updateUserRequest, history, mutate]
  );

  const initialValues: SettingsFormValues = useMemo(
    () => ({
      firstName: user?.name.first || "",
      lastName: user?.name.last || "",
      phone: user?.phone.mobile || "",
      email: user?.email || "",
      personNumber: user?.person_number || "",
    }),
    [user]
  );

  return {
    onSubmit,
    isLoading,
    isSaving,
    initialValues,
  };
};
