import * as React from "react";

import * as Icons from "assets/icons";

import { LayoutWithBg } from "./LayoutWithBg";
import { DoubleBounce } from "./DoubleBounce";
import { Box, Button, Text } from "@chakra-ui/react";

export enum BlockerScreenType {
  Error = "error",
  Progress = "progress",
  Wait = "wait",
}

interface BlockerScreenProps {
  type: BlockerScreenType;
  title?: string;
  content?: React.ReactNode;
  btnText?: string;

  onClick?(): any;
}

const typeToIcon = {
  [BlockerScreenType.Error]: Icons.Error,
  [BlockerScreenType.Wait]: Icons.Hourglass,
};

const getIcon = (type: BlockerScreenType) => {
  if (type === BlockerScreenType.Progress) {
    return null;
  }

  const Icon = typeToIcon[type];
  return <Icon mb={16} boxSize="70px" mx="auto" flexShrink={0} />;
};

export const BlockerScreen = ({
  type,
  title,
  content,
  btnText,
  onClick,
}: BlockerScreenProps) => {
  return (
    <LayoutWithBg>
      {getIcon(type)}
      {(title || type === BlockerScreenType.Error) && (
        <Text textAlign="center" textStyle="h1" as="h1" flexShrink={0}>
          {title || "Hoppsan!"}
        </Text>
      )}
      {content && (
        <Text
          textStyle="h3"
          textAlign="center"
          mt={[10, 16]}
          flexGrow={1}
          as="h3"
        >
          {content}
        </Text>
      )}
      {type === BlockerScreenType.Progress && (
        <Box d="flex" alignItems="center" justifyContent="center" flexGrow={1}>
          <DoubleBounce size={[16, 20]} />
        </Box>
      )}
      {btnText && onClick && (
        <Button onClick={onClick} flexShrink={0}>
          {btnText}
        </Button>
      )}
    </LayoutWithBg>
  );
};
