import {
  createStandaloneToast,
  Box,
  Text,
  Button,
  CloseButton,
} from "@chakra-ui/react";
import { Workbox, messageSW } from "workbox-window";
import { WorkboxLifecycleWaitingEvent } from "workbox-window/utils/WorkboxEvent";
import theme from "theme";

const toast = createStandaloneToast({ theme });

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

const promptUser = (onSuccess?: () => void) => {
  toast({
    position: "bottom",
    duration: 1000000,
    render: () => (
      <Box
        bgColor="white"
        boxShadow="default"
        px={[16]}
        py={[4]}
        zIndex={10000}
        width={["90vw", "400px"]}
        position="relative"
      >
        <CloseButton
          position="absolute"
          top={[2]}
          right={[2]}
          color="grey.400"
          onClick={() => toast.closeAll()}
        />
        <Text textStyle="h3" mb={[4]}>
          Det finns en ny version!
        </Text>
        <Button
          colorScheme="tomato"
          px={[4]}
          py={[2]}
          minH={["auto", "auto"]}
          onClick={onSuccess}
        >
          Ladda om sidan
        </Button>
      </Box>
    ),
  });
};

export function register() {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  if ("serviceWorker" in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    const wb = new Workbox(swUrl);
    let registration: ServiceWorkerRegistration | undefined;

    const showSkipWaitingPrompt = (event: WorkboxLifecycleWaitingEvent) => {
      // `event.wasWaitingBeforeRegister` will be false if this is
      // the first time the updated service worker is waiting.
      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      promptUser(() => {
        wb.addEventListener("controlling", () => window.location.reload());

        if (registration && registration.waiting) {
          messageSW(registration.waiting, { type: "SKIP_WAITING" });
        }
      });
    };

    wb.addEventListener("waiting", showSkipWaitingPrompt);

    wb.register().then((r) => (registration = r));
  }
}
