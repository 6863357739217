const button = {
  baseStyle: {
    width: "100%",
    fontFamily: "main",
    letterSpacing: "0",
    color: "white",
    fontWeight: "semibold",
    lineHeight: "normal",
    boxShadow: "default",

    _focus: {
      boxShadow: "default",
    },
  },

  variants: {
    solid: {
      borderRadius: "6px",
      //   bg: 'black',
    },

    link: {
      boxShadow: "none",
      fontWeight: "bold",

      _hover: {
        textDecoration: "none",
      },

      _focus: {
        boxShadow: "none",
        textDecoration: "none",
      },

      _active: {
        boxShadow: "none",
        textDecoration: "none",
      },
    },
  },

  sizes: {
    md: {
      fontSize: ["sm", "lg"],
      minHeight: [12, 14],
      py: [3, 4],
      px: [4, 8],
      height: "auto",
    },
  },

  defaultProps: {
    colorScheme: "grey",
  },
};

export default button;
