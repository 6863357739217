export interface BankDetailsForm {
  bankName?: string;
  accountNumber: string;
  sortCode: string;
}

export const initialBankDetailsFormValues: BankDetailsForm = {
  bankName: "",
  accountNumber: "",
  sortCode: "",
};
