import { createIcon } from "@chakra-ui/react";

const Hourglass = createIcon({
  displayName: "Hourglass",
  viewBox: "0 0 57 72",
  path: (
    <g fill="currentColor" fillRule="evenodd">
      <path
        fill-opacity=".2"
        d="M49.725 16.125V4.35h6.6V.375H.6V4.35h6.6v11.775c0 4.8 2.325 9.225 6.3 12L25.05 36 13.5 43.95c-3.9 2.7-6.3 7.2-6.3 12v11.775H.6V71.7h55.725v-3.975h-6.6v-11.85c0-4.8-2.325-9.225-6.3-12L31.95 36l11.55-7.95c3.9-2.7 6.225-7.2 6.225-11.925z"
      />
      <path
        fillRule="nonzero"
        d="M49.725 16.125V4.35h6.6V.375H.6V4.35h6.6v11.775c0 4.8 2.325 9.225 6.3 12L25.05 36 13.5 43.95c-3.9 2.7-6.3 7.2-6.3 12v11.775H.6V71.7h55.725v-3.975h-6.6v-11.85c0-4.8-2.325-9.225-6.3-12L31.95 36l11.55-7.95c3.9-2.7 6.225-7.2 6.225-11.925zm-38.55 0V4.35H45.75v11.775c0 3.15-1.425 6.075-3.75 8.1H15c-2.4-2.025-3.825-4.95-3.825-8.1zm30.075 31.05c2.85 1.95 4.575 5.25 4.575 8.7v10.2l-14.7-14.7c-1.425-1.425-3.825-1.425-5.325 0l-14.625 14.7v-10.2c0-3.45 1.725-6.75 4.575-8.7L28.5 38.4l12.75 8.775z"
      />
    </g>
  ),
  defaultProps: {
    color: "tomato.400",
  },
});

export default Hourglass;
