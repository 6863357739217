import { LayoutWithBg } from "components/shared";
import React, { useEffect, useState } from "react";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import { BankId, Pensa } from "assets/icons";
import { Form, Formik } from "formik";
import { object } from "yup";
import { PersonNumberSchema } from "lib/yup-schema";
import {
  FormikCheckbox,
  FormikTextInput,
} from "components/shared/FormikFields";
import {
  useLoginPageData,
  personNumberKey,
  LoginFormValues,
} from "hooks/pages/login";

import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";
import { Redirect } from "react-router-dom";
import { localStorageGetLogin } from "lib/utils";

const getInitialValues = () =>
  ({
    personNumber: localStorage.getItem(personNumberKey) || "",
    savePersonNumber: !!localStorage.getItem(personNumberKey),
  } as LoginFormValues);

const LofinFormSchema = object().shape({
  personNumber: PersonNumberSchema,
});

export const Login = () => {
  const { isLoading, onSubmit } = useLoginPageData();
  const [loggedId, setLoggedIn] = useState(false);

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowLoginPage, {});
    if (localStorageGetLogin()) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  return (
    <>
      {loggedId ? <Redirect to="/home" /> : null}
      <LayoutWithBg>
        <Box
          mb="20vh"
          mx="auto"
          width={[200, 250]}
          display="flex"
          alignItems="center"
        >
          <Pensa boxSize={["30px", "40px"]} mr={4} />
          <Text textStyle="h1" as="h1">
            Pensionskraft
          </Text>
        </Box>

        <Text textStyle="h3" as="h3" mb={8} textAlign="center">
          Logga in med mobilt BankID
        </Text>
        <Formik
          initialValues={getInitialValues()}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={LofinFormSchema}
        >
          <Form>
            <FormikTextInput
              label="Personnummer"
              name="personNumber"
              disabled={isLoading}
            />
            <FormikCheckbox
              label="Spara mitt personnummer"
              name="savePersonNumber"
              disabled={isLoading}
            />
            <Button type="submit" position="relative" disabled={isLoading}>
              Logga in
              <Image position="absolute" right={4} src={BankId} />
            </Button>
          </Form>
        </Formik>
      </LayoutWithBg>
    </>
  );
};
