import { Box, ChakraProps } from "@chakra-ui/react";
import * as React from "react";

import { MeterStar } from "./MeterStar";

interface StarsBarProps extends ChakraProps {
  value: number; // from 0 to 5
  hideEmpty?: boolean;
}

export const StarsBar = ({
  value,
  hideEmpty,
  ...chakraProps
}: StarsBarProps) => {
  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent="space-between"
      {...chakraProps}
    >
      {(Array.apply(null, Array(5)) as number[])
        .map((_, index) => {
          const diff = value - index;
          return diff >= 1 ? 1 : diff >= 0 ? diff : 0;
        })
        .filter((progress) => progress > 0 || !hideEmpty)
        .map((progress, index) => (
          <MeterStar key={`meter_star_${index}`} progress={progress} />
        ))}
    </Box>
  );
};
