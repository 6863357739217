import textStyles from "../foundations/textStyles";

const checkbox = {
  baseStyle: {
    label: {
      ...textStyles.p,
    },
    control: {
      alignSelf: "flex-start",
      _focus: {
        boxShadow: "none",
      },
    },
  },

  sizes: {
    md: {
      control: {
        h: [5],
        w: [5],
      },
      label: {
        fontSize: textStyles.p.fontSize,
      },
    },
  },

  defaultProps: {
    colorScheme: "tomato",
    size: "md",
  },
};

export default checkbox;
