import React from "react";
import { RecommendationType } from "models/funds";
import { Box, Text } from "@chakra-ui/react";
import { setTemporaryState } from "lib/utils";

interface CustomToastProps {
  shellId: string;
  type: RecommendationType;
  close: () => void;
}
export const CustomToast = ({ close, shellId, type }: CustomToastProps) => {
  return (
    <Box color="white" p={5} bg="#3c5577" borderRadius="4px">
      <Text textStyle="p" fontSize={["sm", "md"]} color="white">
        {" "}
        Portföljen borttagen från dina favoriter.
      </Text>
      <Text
        textStyle="p"
        cursor="pointer"
        textDecoration="underline"
        fontSize={["sm", "md"]}
        color="white"
        onClick={() => {
          setTemporaryState(String(shellId), type, "canceled");
          close();
        }}
      >
        Ångra
      </Text>
    </Box>
  );
};
