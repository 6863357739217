import { useMemo, useRef, useState } from "react";
import {
  Skeleton,
  Text,
  Box,
  useDisclosure,
  Collapse,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
// Components
import { RecommendationType } from "models/funds";
import { Card, RecommendedFunds } from "../shared";
import { CustomToast } from "./CustomToast";
// hooks
import {
  useInsuranceShells,
  useShellRecommendations,
  useShellRecommendationsPerfomrance,
} from "hooks/funds";
// other
import {
  formatSignedValue,
  getPerformanceDates,
  setTemporaryState,
  getTemporaryState,
  removeTemporaryState,
} from "lib/utils";
import * as Icons from "assets/icons";
import "animate.css";

interface PortfolioPerformanceProps {
  shellId: string;
  type: RecommendationType;
  removeItem?: (idx: number) => void;
  update?: (value: boolean) => void;
  index?: number;
  updateItem?: (callback: () => void) => void;
  toggleFavourite?: (
    selectedShell: string,
    recommendationType: RecommendationType,
    isCancel: boolean
  ) => void;
}

const portfolioTypeLabel = {
  [RecommendationType.Sinful]: "Standardportfölj",
  [RecommendationType.Sustainable]: "Hållbar portfölj",
};

export const PortfolioPerformance = ({
  shellId,
  type,
  removeItem,
  updateItem,
  update,
  toggleFavourite,
  index,
}: PortfolioPerformanceProps) => {
  const { isLoading: isLoadingShells, shells } = useInsuranceShells();
  const { isLoading: isLoadingRecommendations, recommendations } =
    useShellRecommendations(shellId);
  const { isLoading: isLoadingPerformance, performance } =
    useShellRecommendationsPerfomrance(shellId);

  const isLoading =
    isLoadingRecommendations || isLoadingShells || isLoadingPerformance;

  const shell = useMemo(
    () => shells?.find(({ _id }) => _id === shellId),
    [shells, shellId]
  );
  const recsOfType = useMemo(
    () =>
      recommendations?.find(({ classification }) => classification === type),
    [recommendations, type]
  );
  const performanceOfType = useMemo(
    () => performance?.[type],
    [type, performance]
  );
  const { month, year } = useMemo(() => getPerformanceDates(), []);

  const { isOpen, onToggle } = useDisclosure();

  const toast = useToast();
  const [isRemoving, setRemoving] = useState(false);
  const toastIdRef = useRef<any>(toast);

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  const handleUnfavouriteClick = async (event: any) => {
    event.stopPropagation();
    setRemoving(true);
    if (index !== undefined && removeItem) {
      await new Promise((res) => {
        res(
          setTimeout(() => {
            removeItem(index);
            setRemoving(false);
            if (updateItem) {
              updateItem(async () => {
                if (toggleFavourite) {
                  await toggleFavourite(String(shell?._id), type, false);
                }
                if (update) {
                  update(true);
                }
                setTemporaryState(String(shell?._id), type, "success");
                toastIdRef.current = toast({
                  position: "top",
                  isClosable: true,
                  duration: 3000,
                  onCloseComplete: () => {
                    const status = getTemporaryState(String(shell?._id), type);
                    if (status === "success") {
                      removeTemporaryState(String(shell?._id), type);

                      if (update) {
                        update(true);
                      }
                    } else if (status === "canceled") {
                      removeTemporaryState(String(shell?._id), type);

                      updateItem(async () => {
                        if (toggleFavourite) {
                          await toggleFavourite(String(shell?._id), type, true);
                        }
                        if (update) {
                          update(true);
                        }
                      });
                    }
                  },
                  render: () => (
                    <CustomToast
                      close={close}
                      shellId={String(shell?._id)}
                      type={type}
                    />
                  ),
                });
              });
            }
          }, 1000)
        );
      });
    }
  };
  if (isLoading) {
    return <Skeleton height={["9.5rem", "10.25rem"]} isLoaded={false} />;
  }

  return (
    <Card
      px={[7, 10]}
      py={[4, 6]}
      color="denim.600"
      cursor="pointer"
      onClick={onToggle}
      style={{ position: "relative" }}
      animation={isRemoving ? "bounceOut 1000ms" : ""}
    >
      <Text
        textStyle="p"
        fontWeight="bold"
        letterSpacing="0.5px"
        as="h6"
        mb={1}
      >
        {shell?.pension_institute}
      </Text>

      <Box
        mb={4}
        d="flex"
        flexDir={["column", "column", "row"]}
        justifyContent="space-between"
      >
        <Text textStyle="p">{shell?.insurance_type}</Text>
        {performanceOfType && (
          <Box alignSelf="flex-end">
            <HStack spacing={3}>
              <PerformanceItem
                label={month}
                meanReturn={performanceOfType.total.month}
              />
              <PerformanceItem
                label={year}
                meanReturn={performanceOfType.total.year}
              />
              <div style={{ position: "absolute", top: "16px", right: "16px" }}>
                <Box>
                  <Icons.Heart
                    w={["18px"]}
                    h={["16px"]}
                    onClick={(event) => {
                      handleUnfavouriteClick(event);
                    }}
                  />
                </Box>
              </div>
            </HStack>
          </Box>
        )}
      </Box>
      <Box d="flex" alignItems="center" justifyContent="space-between">
        <Text textStyle="p" fontSize={["md", "xl"]} color="grey.400">
          {portfolioTypeLabel[type]}
        </Text>
        <ChevronDownIcon
          boxSize={5}
          transform={!isOpen ? "rotate(180deg)" : "rotate(0)"}
        />
      </Box>

      <Collapse in={!isOpen} animateOpacity>
        <Box mt={[4]}>
          {recsOfType && <RecommendedFunds recommendations={recsOfType} />}
        </Box>
      </Collapse>
    </Card>
  );
};

interface PerformanceItemProps {
  label: string;
  meanReturn: number;
}

const PerformanceItem = ({ label, meanReturn }: PerformanceItemProps) => (
  <Box>
    <Text textStyle="p" textAlign="right" textTransform="capitalize">
      {label}
    </Text>
    <Box d="flex" alignItems="center">
      {meanReturn > 0 && <Icons.ArrowUp w={21} h={21} marginRight={2} />}
      {meanReturn === 0 && <Icons.ArrowUp w={21} h={21} marginRight={2} />}
      {meanReturn < 0 && <Icons.ArrowDown w={21} h={21} marginRight={2} />}
      <Text textStyle="h2">{formatSignedValue(meanReturn, 1)}%</Text>
    </Box>
  </Box>
);
