import { createIcon } from "@chakra-ui/react";

const Oval = createIcon({
  displayName: "Oval",
  viewBox: "0 0 28 28",
  path: (
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <g transform="translate(-46 -286) translate(33 276)">
          <circle cx="27" cy="24" r="14" />
        </g>
      </g>
    </g>
  ),
  defaultProps: {
    color: "denim.600",
  },
});

export default Oval;
