import { createIcon } from "@chakra-ui/react";

const Help = createIcon({
  displayName: "Help",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M21.895 9.134h-1.292v-.25-.359C20.493 3.833 16.505.072 11.631.18 6.794.287 2.917 4.191 3.028 8.884v.25H1.735C.775 9.134 0 9.887 0 10.818v3.904c0 .932.775 1.684 1.735 1.684h2.77V8.884 8.56C4.578 4.657 7.938 1.541 12 1.648c4.025.071 7.237 3.331 7.163 7.236v3.904c0 3.045-1.994 5.731-4.91 6.699-.48-.932-1.478-1.54-2.622-1.54-1.625 0-2.954 1.289-2.954 2.865 0 1.576 1.33 2.866 2.954 2.866 1.587 0 2.917-1.218 2.954-2.794 2.326-.753 4.209-2.365 5.206-4.478h2.141c.96 0 1.736-.752 1.736-1.684v-3.868c-.037-.932-.813-1.72-1.773-1.72zM3.028 15.01H1.735a.249.249 0 0 1-.258-.25v-3.905c0-.144.11-.251.258-.251h1.293v4.406zm8.566 7.236c-.812 0-1.477-.645-1.477-1.433s.665-1.433 1.477-1.433 1.477.645 1.477 1.433-.665 1.433-1.477 1.433zm10.56-7.487c0 .143-.11.251-.259.251h-1.587a8.948 8.948 0 0 0 .295-2.22v-2.222h1.292c.148 0 .259.108.259.25v3.941z"
    />
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default Help;
