import {
  useAxaInsurances,
  useAxaProducts,
  usePurchaseAxaInsuranceRequest,
} from "hooks/insurances";
import {
  InsurancePurchaseRouteParams,
  InsurancePurchaseRoute,
} from "lib/routes";
import { InsurancePurchaseType } from "models/axa-products";
import { initialBankDetailsFormValues } from "models/bank-details";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

export function useInsurancePurchaseData() {
  const history = useHistory();
  const {
    insuranceType,
    purchaseType,
  } = useParams<InsurancePurchaseRouteParams>();
  const isUpsell = purchaseType === InsurancePurchaseType.Upsell;

  const { isLoading: isLoadingAxaProducts, products } = useAxaProducts();
  const {
    isLoading: isLoadingActiveInsurances,
    insurances,
  } = useAxaInsurances();
  const isLoading = isLoadingAxaProducts || isLoadingActiveInsurances;

  const {
    isLoading: isPurchaseInProgress,
    purchaseAxaInsurance,
  } = usePurchaseAxaInsuranceRequest();
  const insuranceOffer = useMemo(
    () => products?.find((pr) => pr.type === insuranceType),
    [products, insuranceType]
  );

  const [selectedAmount, setSelectedAmount] = useState<number>();
  const [questions, setQuestions] = useState({
    allAnswered: false,
    completed: false,
  });
  const [bankDetails, setBankDetails] = useState({
    form: initialBankDetailsFormValues,
    completed: false,
  });
  const price = selectedAmount
    ? insuranceOffer!.options.grossMonthlyPremium[selectedAmount]
    : 0;
  const productCode = insuranceOffer?.id;

  useEffect(() => {
    window.scrollTo && window.scrollTo({ top: 0 });
  }, [selectedAmount, questions, bankDetails]);

  // redirect to upsell/first purchase based on existing insurances
  useEffect(() => {
    if (isLoadingActiveInsurances) {
      return;
    }
    if (isUpsell && !insurances[insuranceType]) {
      history.push(
        InsurancePurchaseRoute.format({
          insuranceType,
          purchaseType: InsurancePurchaseType.Initial,
        })
      );
    }
    if (!isUpsell && insurances.life) {
      history.push(
        InsurancePurchaseRoute.format({
          insuranceType,
          purchaseType: InsurancePurchaseType.Upsell,
        })
      );
    }
  }, [
    insurances,
    isUpsell,
    isLoadingActiveInsurances,
    history,
    insuranceType,
    purchaseType,
  ]);

  return {
    isSaving: isPurchaseInProgress,
    isUpsell,
    isLoading,
    insuranceType,
    insuranceOffer,
    price,
    productCode,
    selectedAmount,
    setSelectedAmount,
    questions,
    setQuestions,
    bankDetails,
    setBankDetails,
    purchaseAxaInsurance,
  };
}
