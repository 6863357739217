import React from "react";
import { useParams } from "react-router-dom";

import type { AuthCodeRouteParams } from "lib/routes";
import { useAuthCode } from "hooks/auth";

export const AuthCodePage = () => {
  const { code } = useParams<AuthCodeRouteParams>();
  useAuthCode(code);

  return <div>AuthCode: {code}</div>;
};
