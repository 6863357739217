import { Stack, Box, useToken, Skeleton } from "@chakra-ui/react";
import { Card, Layout, CardTitle, CardContent } from "components/shared";
import { PortfolioInfoStarItem } from "components/portfolio-info";
import { usePortfolioInfoData } from "hooks/pages/portfolioInfo";
import { ShellRecommendationsRoute } from "lib/routes";
import { RecommendationType } from "models/funds";
import { useHistory } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import {
  LineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Dot,
} from "recharts";
import React, { useEffect, useState } from "react";
import {
  ChartDataModel,
  AvarageModel,
  ArrayModel,
} from "models/portfolio-info";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";
import { getPerformanceDates } from "../lib/utils";

export const PortfolioInfo = () => {
  const history = useHistory();
  const {
    isLoading,
    isSustainable,
    pensionInstitute,
    insuranceShell,
    perf,
    rating,
    chartData,
    average: averageInShell,
  } = usePortfolioInfoData();
  const [tomato400] = useToken("colors", ["tomato.400"]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [values, setValues] = useState<ChartDataModel>({
    date: 0,
    value: 0,
    percent: 100,
  });
  const [avgPerYear, setAvgPerYear] = useState<AvarageModel>({
    date: 1617235200000,
    percent_change: 2.5,
  });

  const { month, year } = getPerformanceDates();
  const calculateAvarageValue = (payload: ChartDataModel) => {
    let current = new Date(payload.date).getFullYear();
    let prev = current - 1;
    let changesData: Array<ArrayModel> = [];
    let currentFilteredData: Array<ArrayModel> = [];
    let prevFilteredData: Array<ArrayModel> = [];
    chartData &&
      chartData.data.forEach((item) => {
        changesData.push({
          ...item,
          date: new Date(item.date).toISOString(),
        });
      });
    changesData.forEach((item) => {
      if (new Date(item.date).getFullYear() === current) {
        currentFilteredData.push(item);
      }
    });
    changesData.forEach((item) => {
      if (new Date(item.date).getFullYear() === prev) {
        prevFilteredData.push(item);
      }
    });
    if (prevFilteredData.length > 0) {
      if (
        new Date(
          prevFilteredData[prevFilteredData.length - 1].date
        ).getMonth() === 11
      ) {
        setAvgPerYear({
          date: payload.date,
          percent_change:
            (currentFilteredData[currentFilteredData.length - 1].value /
              prevFilteredData[prevFilteredData.length - 1].value -
              1) *
            100,
        });
      } else {
        setAvgPerYear({
          date: payload.date,
          percent_change:
            (currentFilteredData[currentFilteredData.length - 1].value /
              currentFilteredData[0].value -
              1) *
            100,
        });
      }
    } else {
      if (currentFilteredData.length > 1) {
        setAvgPerYear({
          date: payload.date,
          percent_change:
            (currentFilteredData[currentFilteredData.length - 1].value /
              currentFilteredData[0].value -
              1) *
            100,
        });
      } else {
        setAvgPerYear({
          date: payload.date,
          percent_change: payload.percent,
        });
      }
    }
  };

  const handleChangePoint = (data: any) => {
    if (data?.isTooltipActive) {
      if (data?.activePayload) {
        setActiveIndex(data.activeTooltipIndex);
        setValues(data?.activePayload[0]?.payload);
        if (
          new Date(data?.activePayload[0]?.payload.date).getFullYear() !==
          new Date(avgPerYear.date).getFullYear()
        ) {
          calculateAvarageValue(data?.activePayload[0]?.payload);
        }
      }
    }
  };
  const handleClick = (index: number) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (chartData?.data) {
      setValues({
        date: chartData.data[chartData.data.length - 1].date,
        value: chartData.data[chartData.data.length - 1].value,
        percent: chartData.data[chartData.data.length - 1].percent,
      });
      calculateAvarageValue(chartData.data[chartData.data.length - 1]);
      setActiveIndex(chartData.data.length - 1);
    }
    return () => {};
  }, [chartData]);

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowPortfolioInfoPage, {
      pensionInstitute,
      insuranceShell,
      recommendationType: isSustainable
        ? RecommendationType.Sustainable
        : RecommendationType.Sinful,
    });
  }, []);

  return (
    <Layout
      title="Info om portföljen"
      onGoBack={() =>
        history.push(
          ShellRecommendationsRoute.format({
            pensionInstitute,
            insuranceShell,
            recommendationType: isSustainable
              ? RecommendationType.Sustainable
              : RecommendationType.Sinful,
          })
        )
      }
    >
      <Stack spacing={[3, 4]}>
        {chartData && chartData.data.length >= 1 && (
          <Card>
            <CardTitle>Utveckling</CardTitle>
            <Skeleton isLoaded={!isLoading}>
              <Box
                width="100%"
                backgroundColor="#3c5577"
                minHeight="50px"
                height="auto"
                borderRadius="0 0 6px 6px"
                display="flex"
                alignItems="center"
                padding={[2, 4]}
                justifyContent="space-evenly"
                color="#fff"
              >
                <table width="100%">
                  <thead>
                    <tr>
                      <td></td>
                      <td>
                        <Text
                          textStyle="span"
                          color="#fff"
                          textTransform="capitalize"
                          textAlign="right"
                          fontFamily={"main"}
                          fontSize={["sm", "md"]}
                        >
                          {month}
                        </Text>
                      </td>
                      <td>
                        <Text
                          textStyle="span"
                          color="#fff"
                          textAlign="right"
                          fontFamily={"main"}
                          fontSize={["sm", "md"]}
                        >
                          {year}
                        </Text>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Text
                          textStyle="span"
                          color="#fff"
                          fontFamily={"main"}
                          textAlign="left"
                          fontSize={["md", "lg"]}
                        >
                          Portföljen
                        </Text>
                      </td>
                      <td>
                        <Text
                          textStyle="span"
                          color="#fff"
                          fontFamily={"main"}
                          textAlign="right"
                          fontSize={["sm", "md"]}
                        >
                          {perf.month.percent.toLocaleString("sv", {
                            maximumFractionDigits: 1,
                          })}
                          {" %"}
                        </Text>
                      </td>
                      <td>
                        <Text
                          textStyle="span"
                          color="#fff"
                          fontFamily={"main"}
                          textAlign="right"
                          fontSize={["sm", "md"]}
                        >
                          {perf.year.percent.toLocaleString("sv", {
                            maximumFractionDigits: 1,
                          })}
                          {" %"}
                        </Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Text
                          textStyle="span"
                          color="#fff"
                          fontFamily={"main"}
                          textAlign="left"
                          fontSize={["md", "lg"]}
                        >
                          Snitt alla fonder
                        </Text>
                      </td>
                      <td>
                        <Text
                          textStyle="span"
                          fontFamily={"main"}
                          color="#fff"
                          textAlign="right"
                          fontSize={["sm", "md"]}
                        >
                          {averageInShell?.total?.month?.toLocaleString("sv", {
                            maximumFractionDigits: 1,
                          })}
                          {" %"}
                        </Text>
                      </td>
                      <td>
                        <Text
                          textStyle="span"
                          color="#fff"
                          fontFamily={"main"}
                          textAlign="right"
                          fontSize={["sm", "md"]}
                        >
                          {averageInShell?.total?.year?.toLocaleString("sv", {
                            maximumFractionDigits: 1,
                          })}
                          {" %"}
                        </Text>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Skeleton>
          </Card>
        )}
        {isSustainable && (
          <PortfolioInfoStarItem
            isLoading={isLoading}
            pensionInstitute={pensionInstitute}
            insuranceShell={insuranceShell}
            title="Hållbarhet"
            portfolioRating={rating.sustainability.portfolio}
            averageRating={rating.sustainability.allFundsAverage}
          />
        )}
        <PortfolioInfoStarItem
          isLoading={isLoading}
          pensionInstitute={pensionInstitute}
          insuranceShell={insuranceShell}
          title="Fondbetyg"
          portfolioRating={rating.standard.portfolio}
          averageRating={rating.standard.allFundsAverage}
        />
        <PortfolioInfoStarItem
          isLoading={isLoading}
          pensionInstitute={pensionInstitute}
          insuranceShell={insuranceShell}
          title="Avgifter"
          portfolioRating={rating.ourRating.portfolio}
          averageRating={rating.ourRating.allFundsAverage}
        />
      </Stack>
    </Layout>
  );
};

const СustomDot = (props: any) => {
  return (
    <>
      {props.index === props.activeIndex ? (
        <Dot
          cx={props.cx}
          cy={props.cy}
          points={props.points}
          cursor="pointer"
          strokeWidth="3px"
          stroke="#e63533"
          fill="white"
          r={7}
        />
      ) : (
        <Dot
          {...props}
          stroke="tomato400"
          strokeWidth="3px"
          fill="#e63533"
          r={6}
          cursor="pointer"
        />
      )}
    </>
  );
};
