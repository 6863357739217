export const swedishBanks = [
  "Swedbank",
  "Handelsbanken",
  "Nordea",
  "SEB",
  "Danske Bank",
  "ICA Banken",
  "Avanza Bank",
  "Ikanobanken",
  "Skandiabanken",

  "Attmars Sparbank",
  "Bank2",
  "Bergslagens Sparbank",
  "Bjursås Sparbank",
  "Carnegie Investment Bank",
  "Collector Bank AB",
  "Dalslands Sparbank",
  "EFG Bank",
  "Ekeby Sparbank",
  "Ekobanken",
  "Erik Penser Bankaktiebolag",
  "Eskilstuna Rekarne Sparbank",
  "Falkenbergs Sparbank",
  "Forex Bank",
  "Frenninge Sparbank",
  "Fryksdalens Sparbank",
  "Färs & Frosta Sparbank",
  "HQ Bank",
  "Hudiksvalls Sparbank",
  "Häradssparbanken Mönsterås",
  "Högsby Sparbank",
  "Ivetofta Sparbank",
  "JAK Medlemsbank",
  "Kaupthing Bank Sverige",
  "Kinda-Ydre Sparbank",
  "Laholms Sparbank",
  "Landshypotek Bank AB",
  "Lekebergs Sparbank",
  "Leksands Sparbank",
  "Länsförsäkringar Bank",
  "Lönneberga-Tuna-Vena Sparbank",
  "Markaryds Sparbank",
  "MedMera Bank",
  "Mjöbäcks Sparbank",
  "Nordals Härads Sparbank",
  "Nordnet Bank",
  "Norrbärke Sparbank",
  "Närs Sparbank",
  "OK-Q8 Bank",
  "Orusts Sparbank",
  "Resurs Bank",
  "Roslagens Sparbank",
  "Sala Sparbank",
  "Santader Cosumerbank",
  "SBAB Bank",
  "Sidensjö Sparbank",
  "Skurups Sparbank",
  "Snapphanebygdens Sparbank",
  "Sparbanken 1826",
  "Sparbanken Alingsås",
  "Sparbanken Boken",
  "Sparbanken Eken",
  "Sparbanken Finn",
  "Sparbanken Gotland",
  "Sparbanken Gripen",
  "Sparbanken Gute",
  "Sparbanken Göinge",
  "Sparbanken i Enköping",
  "Sparbanken i Karlshamn",
  "Sparbanken Lidköping",
  "Sparbanken Nord",
  "Sparbanken Skaraborg",
  "Sparbanken Syd",
  "Sparbanken Tanum",
  "Sparbanken Tranemo",
  "Sparbanken Västra Mälardalen",
  "Swedbank Sjuhärad",
  "Södra Dalarnas Sparbank",
  "Södra Hestra Sparbank",
  "Sölvesborg-Mjällby Sparbank",
  "Sörmlands Sparbank",
  "Tidaholms Sparbank",
  "Tjustbygdens Sparbank",
  "Tjörns Sparbank",
  "Ulricehamns Sparbank",
  "Vadstena Sparbank",
  "Valdemarsviks Sparbank",
  "Varbergs Sparbank",
  "Vimmerby Sparbank",
  "Virserums Sparbank",
  "Volvofinans Bank",
  "Westra Wermlands Sparbank",
  "Ålems Sparbank",
  "Åse och Viste Härads Sparbank",
  "Åtvidabergs Sparbank",
  "Ölands Bank"
];
