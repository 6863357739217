import { Text } from "@chakra-ui/react";
import * as Icons from "assets/icons";
import { Card } from "./Card";

interface ListItemProps {
  icon?: string;
  label: string;
  onClick(): void;
}

export const ListItem = ({ label, onClick }: ListItemProps) => {
  return (
    <Card
      px={[4, 6]}
      py={[3, 4]}
      minH={[12]}
      d="flex"
      alignItems="center"
      cursor="pointer"
      onClick={onClick}
    >
      <Icons.Oval boxSize={[7]} alt="Pension Institute Logo" mr={[4]} />
      <Text textStyle="h3">{label}</Text>
    </Card>
  );
};
