import { createIcon } from "@chakra-ui/react";

const Settings = createIcon({
  displayName: "Settings",
  viewBox: "0 0 23 23",
  path: (
    <g fill="currentColor" fillRule="nonzero">
      <path d="M19.925 8.53a8.82 8.82 0 0 0-.486-1.16l1.03-1.03.998-.997-.998-.999-1.976-1.976-.998-.998-.999.998-1.033 1.033a8.816 8.816 0 0 0-1.162-.477V.057H8.683V2.93a8.828 8.828 0 0 0-1.16.485l-1.029-1.03-.998-.998-.998.999-1.976 1.976-.999.998.999.998 1.032 1.033a8.812 8.812 0 0 0-.477 1.163H.21v5.618h2.873c.134.397.296.784.485 1.159l-1.03 1.03-.997.998.998.998 1.976 1.976.998.998.998-.998L7.544 19.3c.376.186.765.346 1.163.477v2.867h5.618v-2.872a8.82 8.82 0 0 0 1.16-.486l1.029 1.03.998.998.998-.998 1.976-1.976.998-.999-.998-.998-1.033-1.033c.187-.376.346-.764.477-1.163h2.867V8.53h-2.872zm1.46 4.206h-2.53a7.446 7.446 0 0 1-1.153 2.818v.001l1.786 1.786-1.976 1.976-1.785-1.785a7.44 7.44 0 0 1-2.775 1.163c-.014.002-.025.008-.039.01v2.527H10.12v-2.53A7.443 7.443 0 0 1 7.3 17.548l-1.787 1.786-1.976-1.976 1.785-1.785a7.46 7.46 0 0 1-.702-1.275l-.002-.003a7.434 7.434 0 0 1-.459-1.498c-.002-.013-.008-.025-.01-.038H1.621V9.966h2.531a7.448 7.448 0 0 1 1.153-2.82L3.52 5.36l1.976-1.976L7.28 5.169a7.441 7.441 0 0 1 2.776-1.163c.013-.002.025-.008.038-.01V1.468h2.795v2.53a7.442 7.442 0 0 1 2.819 1.154l1.787-1.786 1.975 1.975-1.784 1.785a7.436 7.436 0 0 1 1.162 2.776c.003.013.009.025.011.038h2.526v2.795z" />
      <path d="M11.502 7.98a3.372 3.372 0 1 0 0 6.743 3.372 3.372 0 0 0 0-6.744zm0 5.332a1.96 1.96 0 1 1 0-3.921 1.96 1.96 0 0 1 0 3.92z" />
    </g>
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default Settings;
