import { LoginRoute } from "lib/routes";
import {
  localStorageGetLogin,
  localStorageRemoveLogin,
  localStorageSetLogin,
  transformResponse,
} from "lib/utils";
import type { ApiError } from "models/api";
import { ApiError as ApiException } from "models/auth";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import useSWR, { ConfigInterface } from "swr";
import * as cookie from "cookie";

export const useApiSWR = <Data = any, ErrorInfo = any>(
  key: string | undefined,
  config?: ConfigInterface<Data, ApiError<ErrorInfo>>
) => {
  const history = useHistory();
  const swrData = useSWR<Data, ApiError<ErrorInfo>>(
    key || null,
    (url) =>
      fetch(url).then(async (response) => {
        if (response.ok) {
          if (!localStorageGetLogin()) {
            localStorageSetLogin();
          }
          try {
            return await response.json();
          } catch (error) {
            throw new ApiException(response.status, error);
          }
        } else {
          if (response.status === 401) {
            localStorageRemoveLogin();
            history.push(LoginRoute);
          }
          throw new ApiException(response.status, null);
        }
      }),
    config
  );

  // const isUnauthrized = swrData.error?.status === 401;
  // useEffect(() => {
  //   if (isUnauthrized) {
  //     history.push(LoginRoute);
  //   }
  // }, [history, isUnauthrized]);

  return swrData;
};

export const useMutationRequest = <B = any, R = any>(
  endpoint: string,
  method = "POST"
) => {
  const [isLoading, setIsLoading] = useState(false);
  const request = useMemo(
    () =>
      async (
        body: B | undefined,
        config?: { onSuccess?: (resp: R) => any; onError?: () => any }
      ) => {
        try {
          setIsLoading(true);
          const cookies = cookie.parse(document.cookie);
          const resp = await fetch(endpoint, {
            method,
            body: body && JSON.stringify(body),
            headers: new Headers({
              "Content-Type": "application/json",
              "pensionskraft-api-key": process.env.REACT_APP_API_KEY || "",
              "X-XSRF-TOKEN": cookies["XSRF-TOKEN"],
            }),
          }).then(transformResponse);
          setIsLoading(false);
          config?.onSuccess?.(resp);
        } catch (e) {
          setIsLoading(false);
          config?.onError?.();
        }
      },
    [endpoint, method]
  );

  return {
    isLoading,
    request,
  };
};
