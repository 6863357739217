import React from "react";
import cn from "classnames";

import { useHistory, useParams } from "react-router-dom";

import LoadingOverlay from "../loadingOverlay/LoadingOverlay";
import RippleEffect from "../rippleEffect/RippleEffect";
import WithInnerHeight from "../withInnerHeight/WithInnerHeight";
import { introImgs } from "assets/intro";
import { LoginRoute, IntroRoute, HomeRoute } from "lib/routes";
// import { intoWatchedAction } from "@state/user";
import {
  getIntroWatched,
  hideSplashScreen,
  localStorageGetLogin,
  setIntroWatched,
} from "lib/utils";

import "./intro.scss";
import { useState, useEffect } from "react";

interface IntroProps {}
const titles = ["Välkommen", "Välja fonder", "Följ hur det går"];
const descriptions = [
  "Du får tips och råd om hur du på enklaste och bästa sätt tar hand om din pension.",
  "Pensionskraft hjälper dig också att välja bra pensionsfonder. Fonderna som väljs har låga avgifter, höga fondbetyg och haft bättre utveckling än andra fonder.",
  "Genom att hjärta en portfölj så hamnar dom som favorit. Då får du varje månad ett mail med info om hur det går för portföljen.",
];
export const Intro = ({}: IntroProps) => {
  const { step: currentStep }: any = useParams();
  const history = useHistory();
  const [step, setStep] = useState(1);

  function onGoToStep(step: number) {
    setStep(step);
    history.push(IntroRoute.format({ step }));
  }
  function onIntroWatchded() {
    console.log("Intro is watched.");
    setIntroWatched();

    localStorageGetLogin() ? onGoToHome() : onGoToLogin();
  }
  function onGoToLogin() {
    history.push(LoginRoute);
  }
  function onGoToHome() {
    history.push(HomeRoute);
  }

  const [showLoading, setShowLoading] = useState(false);
  const index = step - 1;

  const onNext = () => {
    if (index === 2) {
      setShowLoading(true);
      onIntroWatchded();
    } else {
      onGoToStep(step + 1);
    }
  };

  useEffect(() => {
    if (currentStep) {
      setStep(Number(currentStep));
    }

    hideSplashScreen();
    if (getIntroWatched()) {
      localStorageGetLogin() ? onGoToHome() : onGoToLogin();
    }
  }, []);

  return (
    <WithInnerHeight>
      <LoadingOverlay isShown={showLoading} />
      <div className="Intro">
        <div className="Intro__top">
          <img src={introImgs[index]} alt="alt introImgs" />
        </div>
        <div className="Intro__bottom">
          <h3 className="Intro__title">{titles[index]}</h3>
          <div
            className={cn(
              "Intro__textWrapper",
              `Intro__textWrapper--step${step}`
            )}
          >
            {descriptions?.map((text, index) => {
              return (
                <p key={index} className="Intro__text">
                  {text}
                </p>
              );
            })}
          </div>
          <div className="Intro__nav">
            {index !== 0 && (
              <RippleEffect
                className="Intro__navAction Intro__navAction--prev"
                rounded
                onClick={() => onGoToStep(step - 1)}
              >
                Föregående
              </RippleEffect>
            )}
            <div className="Intro__navBullets">
              <div
                className={cn("Intro__navBullet", {
                  "Intro__navBullet--active": index === 0,
                })}
              />
              <div
                className={cn("Intro__navBullet", {
                  "Intro__navBullet--active": index === 1,
                })}
              />
              <div
                className={cn("Intro__navBullet", {
                  "Intro__navBullet--active": index === 2,
                })}
              />
            </div>

            <RippleEffect
              className={cn("Intro__navAction", {
                "Intro__navAction--primary": index === 2,
              })}
              rounded
              onClick={onNext}
            >
              {index === 2 ? "Sätt igång!" : "Nästa"}
            </RippleEffect>
          </div>
        </div>
      </div>
    </WithInnerHeight>
  );
};
