import { Login, Logout, PersonalLink } from "api/endpoints";
import { useApiSWR, useMutationRequest } from "hooks/api";
import { FavoritesRoute, HomeRoute } from "lib/routes";
import {
  AccessCodeResponse,
  LinkActions,
  LoginRequestBody,
  LoginResponse,
  MessageCodes,
} from "models/auth";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "context/auth";
import { HeapEventNames, identify, trackHeapEvent } from "lib/analytics";
import { localStorageRemoveLogin, localStorageSetLogin } from "lib/utils";

export const useAuthCode = (code: string) => {
  const history = useHistory();
  const { userId, setUserId } = useContext(AuthContext);

  const { data } = useApiSWR<AccessCodeResponse>(PersonalLink(code));

  const { loginRequest } = useLoginRequest();

  useEffect(() => {
    loginRequest(
      { username: "undefined", password: code },
      {
        onSuccess: (resp) => {
          localStorageSetLogin();
          setUserId(resp._id);
          identify(resp._id);
          trackHeapEvent(HeapEventNames.LoggedInUsingCode, {
            userId: resp._id,
          });
        },
      }
    );
  }, [code, loginRequest, setUserId]);

  useEffect(() => {
    if (!data || !userId) {
      localStorageRemoveLogin();
      return;
    }

    const action = data?.link_action;

    switch (action) {
      case LinkActions.ShowMessage:
        const payload = data?.payload;
        if (payload === MessageCodes.FundPerformance) {
          localStorageSetLogin();
          history.push(FavoritesRoute);
          return;
        }
        break;
      default:
        history.push(HomeRoute);
    }
  }, [data, history, userId]);
};

export const useLoginRequest = () => {
  const { isLoading, request } = useMutationRequest<
    LoginRequestBody,
    LoginResponse
  >(Login);

  return {
    loginRequest: request,
    isLoading,
  };
};

export const useLogoutRequest = () => {
  const { isLoading, request } = useMutationRequest<undefined, unknown>(
    Logout,
    "GET"
  );

  return {
    logoutRequest: request,
    isLoading,
  };
};
