import { IconButton } from "@chakra-ui/react";

export const ClickableIcon: typeof IconButton = (props) => {
  return (
    <IconButton
      width="fit-content"
      bgColor="transparent"
      boxShadow="none"
      _hover={{
        bgColor: "transparent",
        boxShadow: "none",
      }}
      _focus={{
        bgColor: "transparent",
        boxShadow: "none",
      }}
      _active={{
        bgColor: "transparent",
        boxShadow: "none",
      }}
      sx={{
        p: [4, 6],
        m: [-4, -6],
      }}
      {...props}
    ></IconButton>
  );
};
