import { Link } from "@chakra-ui/react";
import { BankDetails, Layout } from "components/shared";
import { BankDetailsComponentProps } from "models/insurance-purchase";
import React, { FC } from "react";

export const LifeInsurancBankDetails: FC<BankDetailsComponentProps> = ({
  initalFormValues,
  price,
  onPrev,
  onNext,
}) => {
  return (
    <Layout title="Kontouppgifter" onGoBack={onPrev}>
      <BankDetails
        initialValues={initalFormValues}
        subscriptionInfo={
          <>
            Försäkringen kostar <strong>{price.toLocaleString("sv")} kr</strong>{" "}
            per månad och betalas via autogiro.
          </>
        }
        termsInfo={
          <>
            Jag accepterar autogirovillkoren som finns på{" "}
            <Link
              target="_blank"
              href="https://www.pensionskraft.se/livforsakring/"
              rel="noreferrer"
              fontWeight="semibold"
              textDecoration="underline"
            >
              www.pensionskraft.se/livforsakring
            </Link>{" "}
            och godkänner att premien dras månadsvis via autogiro från
            nedanstående konto:
          </>
        }
        onSubmit={onNext}
      />
    </Layout>
  );
};
