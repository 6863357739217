import { createIcon } from "@chakra-ui/react";

const Phone = createIcon({
  displayName: "Phone",
  viewBox: "0 0 20 32",
  path: (
    <g fill="none" fillRule="evenodd">
      <path fillRule="nonzero" d="M-6 0h32v32H-6z" />
      <g fill="currentColor">
        <path d="M9.49 25.537a1.096 1.096 0 1 0 0 2.193 1.096 1.096 0 0 0 0-2.193z" />
        <path
          fillRule="nonzero"
          d="M15.969 0H3.148A3.148 3.148 0 0 0 0 3.148v25.704A3.148 3.148 0 0 0 3.148 32h12.82a3.148 3.148 0 0 0 3.148-3.148V3.148A3.148 3.148 0 0 0 15.97 0zm1.049 28.852c0 .58-.47 1.05-1.05 1.05H3.149c-.58 0-1.05-.47-1.05-1.05V3.148c0-.58.47-1.05 1.05-1.05h12.82c.58 0 1.05.47 1.05 1.05v25.704z"
        />
      </g>
    </g>
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default Phone;
