import React from "react";

import { PortfolioPerformance } from "components/favorites";
import { RecommendationType } from "models/funds";

interface RecommendationFollowedModel {
  classification: RecommendationType;
  date: string;
  insurance_shell: string;
  _id: string;
}

interface FavouritesListProps {
  favourites: Array<RecommendationFollowedModel>;
  handleRemoveFavourite: (idx: number) => void;
  setUpdating: (value: boolean) => void;
  handleUpdate: (callback: () => void) => void;
  toggle: (
    selectedShell: string,
    recommendationType: RecommendationType,
    isCancel: boolean
  ) => void;
}

export const FavouritesList = ({
  favourites,
  handleRemoveFavourite,
  setUpdating,
  handleUpdate,
  toggle,
}: FavouritesListProps) => {
  return (
    <>
      {favourites.map(
        (
          { insurance_shell, classification }: RecommendationFollowedModel,
          index: number
        ) => (
          <PortfolioPerformance
            key={insurance_shell + classification}
            shellId={insurance_shell}
            type={classification}
            removeItem={handleRemoveFavourite}
            update={setUpdating}
            updateItem={handleUpdate}
            index={index}
            toggleFavourite={toggle}
          />
        )
      )}
    </>
  );
};
