import { PortfolioInfoRouteParams } from "lib/routes";
import { RecommendationType } from "models/funds";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  useAverageInShell,
  useInsuranceShells,
  useShellRecommendationsPerfomrance,
} from "../funds";

export const usePortfolioInfoData = () => {
  const params = useParams<PortfolioInfoRouteParams>();
  const pensionInstitute = decodeURIComponent(params.pensionInstitute);
  const insuranceShell = decodeURIComponent(params.insuranceShell);
  const recommendationType = params.recommendationType;

  const { isLoading: isLoadingShells, shells } = useInsuranceShells();
  const selectedShell = useMemo(
    () =>
      shells?.find(({ insurance_type }) => insurance_type === insuranceShell),
    [insuranceShell, shells]
  );

  const { isLoading: isLoadingPerformance, performance } =
    useShellRecommendationsPerfomrance(selectedShell?._id);

  const { isLoading: isLoadingAverage, average } = useAverageInShell(
    selectedShell?._id
  );

  const isSustainable = recommendationType === RecommendationType.Sustainable;

  const rating = useMemo(() => {
    return {
      sustainability: {
        portfolio:
          performance?.sustainable?.weightedMetrics.morningstarSustainability ??
          0,
        allFundsAverage:
          performance?.allFundsAverages.morningstarSustainability ?? 0,
      },
      standard: {
        portfolio: performance?.sinful?.weightedMetrics.morningstarRating ?? 0,
        allFundsAverage: performance?.allFundsAverages.morningstarRating ?? 0,
      },
      ourRating: {
        portfolio: performance?.sinful?.weightedMetrics.ourRating ?? 0,
        allFundsAverage: performance?.allFundsAverages.ourRating ?? 0,
      },
    };
  }, [performance]);

  const chartData = useMemo(() => {
    const data = Object.entries(
      (recommendationType === RecommendationType.Sinful
        ? performance?.sinful
        : performance?.sustainable
      )?.monthlyChangesWithIndex || {}
    ).map(([date, value]) => ({
      date: new Date(date).getTime(),
      value: value.index,
      percent: value.percent,
    }));

    if (!data.length) {
      return null;
    }

    let minVal = data[0].value,
      maxVal = data[0].value,
      minDate = data[0].date,
      maxDate = data[0].date;

    data.forEach(({ value, date }) => {
      if (value > maxVal) {
        maxVal = value;
      }
      if (value < minVal) {
        minVal = value;
      }
      if (date > maxDate) {
        maxDate = date;
      }
      if (date < minDate) {
        minDate = date;
      }
    });

    const datePadding = (maxDate - minDate) * 0.1;
    const valuePadding = (maxVal - minVal) * 0.3;

    return {
      data,
      domainX: [minDate - datePadding, maxDate + datePadding],
      domainY: [minVal - valuePadding, maxVal + valuePadding],
    };
  }, [performance, recommendationType]);

  const MonthlyAndYearValues = () => {
    const data = Object.entries(
      (recommendationType === RecommendationType.Sinful
        ? performance?.sinful
        : performance?.sustainable
      )?.monthlyChangesWithIndex || {}
    ).map(([date, value]) => ({
      date: date,
      value: value.index,
      percent: value.percent,
    }));

    if (isSustainable) {
      return {
        month: {
          date: new Date(data[data.length - 1]?.date!),
          percent: data[data.length - 1]?.percent || 0,
        },
        year: {
          date: new Date().toISOString(),
          percent: performance?.sustainable?.total?.year || 0,
        },
      };
    } else {
      return {
        month: {
          date: new Date(data[data.length - 1]?.date!),
          percent: data[data.length - 1]?.percent || 0,
        },
        year: {
          date: new Date().toISOString(),
          percent: performance?.sinful?.total?.year || 0,
        },
      };
    }
  };

  const perf = MonthlyAndYearValues();

  return {
    isLoading: isLoadingShells || isLoadingPerformance || isLoadingAverage,
    isSustainable,
    pensionInstitute,
    insuranceShell,
    rating,
    perf,
    chartData,
    average,
  };
};
