import { InsurancePurchaseType, InsuranceType } from "models/axa-products";
import { RecommendationType } from "models/funds";
import { stringify } from "query-string";

export const HomeRoute = "/";
export const LoginRoute = "/login";
export const LoginWaitingRoute = "/login/waiting";

export interface AuthCodeRouteParams {
  code: string;
}
export const AuthCodeRoute = "/!:code";

export interface ErrorRouteQuery
  extends Record<string, string | boolean | undefined> {
  title?: string;
  message?: string;
  redirectUrl?: string;
  reload?: boolean;
}

export const ErrorRoute = {
  template: "/error",
  format: (query: ErrorRouteQuery) => `/error?${stringify(query)}`,
};

export const PensionInstitutesRoute = "/pension-institutes";

export interface InsuranceShellsRouteParams {
  pensionInstitute: string;
}
export const InsuranceShellsRoute = {
  template: `${PensionInstitutesRoute}/:pensionInstitute/shells`,
  format: ({ pensionInstitute }: InsuranceShellsRouteParams) =>
    `${PensionInstitutesRoute}/${encodeURIComponent(pensionInstitute)}/shells`,
};

export interface ShellRecommendationsRouteParams
  extends InsuranceShellsRouteParams {
  insuranceShell: string;
  recommendationType: RecommendationType;
}
export const ShellRecommendationsRoute = {
  template: `${InsuranceShellsRoute.template}/:insuranceShell/recommendations/:recommendationType`,
  format: ({
    pensionInstitute,
    insuranceShell,
    recommendationType,
  }: ShellRecommendationsRouteParams) =>
    `${InsuranceShellsRoute.format({
      pensionInstitute,
    })}/${encodeURIComponent(
      insuranceShell
    )}/recommendations/${encodeURIComponent(recommendationType)}`,
};

export interface PortfolioInfoRouteParams
  extends ShellRecommendationsRouteParams {}
export const PortfolioInfoRoute = {
  template: `${ShellRecommendationsRoute.template}/info`,
  format: (params: PortfolioInfoRouteParams) =>
    `${ShellRecommendationsRoute.format(params)}/info`,
};

export const SettingsRoute = "/settings";
export const SupportRoute = "/support";
export const InsurancesRoute = "/insurances";
export interface InsurancePurchaseRouteParams {
  insuranceType: InsuranceType;
  purchaseType?: InsurancePurchaseType;
}
export const InsurancePurchaseRoute = {
  template: `${InsurancesRoute}/:insuranceType/:purchaseType`,
  format: ({ insuranceType, purchaseType }: InsurancePurchaseRouteParams) =>
    `${InsurancesRoute}/${insuranceType}/${purchaseType}`,
};
export const FavoritesRoute = "/favorites";
export const AllPortfoliosRoute = "/all-portfolios";

export const NewslettersRoute = "/newsletters";

export const IntroRoute = {
  template: `/intro/:step`,
  format: ({ step }: any) => `/intro/${step}`,
};
