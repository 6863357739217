import * as React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { BankDetailsForm } from "models/bank-details";
import { RequiredStringSchema, SwedishBankSchema } from "lib/yup-schema";
import { swedishBanks } from "assets/swedishBanks";
import { Button, Text } from "@chakra-ui/react";
import { FormikSelect, FormikTextInput } from "./FormikFields";

const bankNameOptions = swedishBanks.map((bank) => ({
  label: bank,
  value: bank,
}));
export const AutogiroFormSchema = Yup.object().shape({
  bankName: SwedishBankSchema,
  sortCode: RequiredStringSchema,
  accountNumber: RequiredStringSchema,
});

interface BankDetailsProps {
  initialValues: BankDetailsForm;

  subscriptionInfo: React.ReactNode;
  termsInfo: React.ReactNode;
  title?: string;

  submitBtnText?: string;
  onSubmit(form: BankDetailsForm): any;
}

export const BankDetails = function ({
  initialValues,
  subscriptionInfo,
  termsInfo,
  submitBtnText,
  title,
  onSubmit,
}: BankDetailsProps) {
  return (
    <>
      <Text textStyle="p" mb={[6, 8]}>
        {subscriptionInfo}
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={AutogiroFormSchema}
        enableReinitialize
      >
        <Form
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
          noValidate
          autoComplete="off"
        >
          <Text textStyle="h6" as="h6" mb={[2, 3]}>
            {title || "Autogiromedgivande"}
          </Text>
          <Text textStyle="p" mb={[2, 3]}>
            {termsInfo}
          </Text>
          <FormikSelect name="bankName" label="Bank" placeholder="Välj...">
            {bankNameOptions.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </FormikSelect>
          <FormikTextInput
            label="Clearingnummer"
            name="sortCode"
            type="text"
            inputMode="numeric"
          />
          <FormikTextInput
            label="Kontonummer"
            name="accountNumber"
            type="text"
            inputMode="numeric"
          />

          <Button
            type="submit"
            colorScheme="tomato"
            position="relative"
            mt={[6, 8]}
          >
            {submitBtnText || "Nästa"}
          </Button>
        </Form>
      </Formik>
    </>
  );
};
