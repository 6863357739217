import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
} from "@chakra-ui/react";
import { useEmailModalData } from "./hooks";
import { object } from "yup";
import { EmailSchema } from "lib/yup-schema";
import React from "react";
import { Form, Formik } from "formik";
import { FormikTextInput } from "components/shared";

export const EmailModalFormSchema = object().shape({
  email: EmailSchema,
});

export const EmailModal = () => {
  const { isSaving, isOpened, initialValues, onSubmit } = useEmailModalData();

  return (
    <Modal isOpen={isOpened} onClose={() => {}} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Vi saknar en emailadress till dig</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={EmailModalFormSchema}
          >
            <Form
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <FormikTextInput
                label="E-post"
                name="email"
                type="email"
                disabled={isSaving}
              />

              <Button
                type="submit"
                colorScheme="tomato"
                position="relative"
                disabled={isSaving}
                mt={[6, 8]}
              >
                Spara
              </Button>
            </Form>
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
