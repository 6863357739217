import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Close, HelpPerson } from "assets/icons";

interface HelpButtonProps {
  question: string;
  modalContent?: React.ReactNode;
}

export const HelpButton = ({ question, modalContent }: HelpButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        position="relative"
        colorScheme="tomato"
        mt="auto"
        onClick={onOpen}
        justifyContent="flex-start"
        paddingRight={["4.5rem"]}
      >
        <Text zIndex={1} whiteSpace="normal">
          {question}
        </Text>
        <HelpPerson
          boxSize={[10, 14]}
          position="absolute"
          bottom="0"
          right={4}
        />
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="lg"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{question}</ModalHeader>
          <ModalBody>{modalContent}</ModalBody>
          <Close boxSize={["30px", "40px"]} mr={4} onClick={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};
