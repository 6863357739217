import {
  Text,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Link,
} from "@chakra-ui/react";
import * as Icons from "assets/icons";
import { Layout, HelpButton } from "components/shared";
import { Portfolio } from "components/shell-recommendations";
import { useShellRecommendationsData } from "hooks/pages/shellRecommendations";
import {
  ShellRecommendationsRouteParams,
  InsuranceShellsRoute,
  PortfolioInfoRoute,
  ShellRecommendationsRoute,
  PensionInstitutesRoute,
} from "lib/routes";
import isEmpty from "lodash-es/isEmpty";
import { RecommendationType } from "models/funds";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import lottie from "lottie-web";
import heartAnim from "assets/animations/heart_animation.json";
import { isPPM, returnLinkValue } from "lib/utils";
import { usePPMConsent } from "hooks/user";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";

export const ShellRecommendations = () => {
  const params = useParams<ShellRecommendationsRouteParams>();
  const pensionInstitute = decodeURIComponent(params.pensionInstitute);
  const insuranceShell = decodeURIComponent(params.insuranceShell);
  const recommendationType = decodeURIComponent(
    params.recommendationType
  ) as RecommendationType;
  const history = useHistory();

  // const { isConsentGiven, isUserLoaded } = usePPMConsent();

  const {
    isLoading,
    isSavingFavorites,
    recommendations,
    isFavorite,
    fundChangeLink,
    onToggleFavorite,
  } = useShellRecommendationsData(pensionInstitute, insuranceShell, recommendationType);

  // redirect from this route if PPM selected and no consent given
  // useEffect(() => {
  //   if (isUserLoaded && isPPM(pensionInstitute) && !isConsentGiven) {
  //     history.push(PensionInstitutesRoute);
  //   }
  // }, [isConsentGiven, pensionInstitute, history, isUserLoaded]);

  // redirect from this route if we don't have recommendations data
  useEffect(() => {
    if (!isLoading && isEmpty(recommendations)) {
      history.push(InsuranceShellsRoute.format({ pensionInstitute }));
    }
  }, [isLoading, recommendations, history, pensionInstitute]);

  const [playAnim, setPlayAnim] = useState(false);
  const animContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animContainer.current!,
      renderer: "svg",
      loop: false,
      autoplay: false,
      name: "heart",
      animationData: heartAnim,
    });
    animation.addEventListener("complete", () => setPlayAnim(false));

    return () => animation.destroy();
  }, []);

  const onFavoriteClick = useCallback(() => {
    if (isSavingFavorites) {
      return;
    }

    if (!isFavorite) {
      setPlayAnim(true);
      lottie.stop("heart");
      lottie.play("heart");
    }
    onToggleFavorite();
  }, [isSavingFavorites, isFavorite, onToggleFavorite]);

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowShellRecommendationPage, {
      pensionInstitute,
      insuranceShell,
    });
  }, []);

  return (
    <Layout
      title="Portföljer"
      onGoBack={() =>
        isPPM(pensionInstitute)
          ? history.push(PensionInstitutesRoute)
          : history.push(InsuranceShellsRoute.format({ pensionInstitute }))
      }
      isEndIconDisabled={isLoading || (isSavingFavorites && isFavorite)}
      endIcon={
        <>
          {!(playAnim || isLoading) && (
            <>
              {isFavorite ? (
                <Icons.Heart w={["18px"]} h={["16px"]} />
              ) : (
                <Icons.HeartOutlined w={["18px"]} h={["16px"]} />
              )}
            </>
          )}
          <Box
            d={playAnim ? "block" : "none"}
            mx={["-5px"]}
            my={["-6px"]}
            boxSize={["28px"]}
            ref={animContainer}
          ></Box>
        </>
      }
      onEndIconClick={onFavoriteClick}
    >
      <Box d="flex" mb={[5, 7]} width="100%">
        <Icons.Oval boxSize={[7]} alt="Pension Institute Logo" mr={[4]} />
        <Text textStyle="h3" color="denim.600">
          {pensionInstitute} - {insuranceShell}
        </Text>
      </Box>

      <Tabs
        index={recommendationType === RecommendationType.Sinful ? 0 : 1}
        onChange={(index) =>
          history.push(
            ShellRecommendationsRoute.format({
              insuranceShell,
              pensionInstitute,
              recommendationType:
                index === 0
                  ? RecommendationType.Sinful
                  : RecommendationType.Sustainable,
            })
          )
        }
        isFitted
        isLazy
      >
        <TabList>
          <Tab isDisabled={isSavingFavorites}>Standard</Tab>
          <Tab isDisabled={isSavingFavorites}>Hållbar</Tab>
        </TabList>

        <TabPanels>
          <TabPanel padding={[0,2]}>
            <Portfolio
              recommendations={recommendations?.[RecommendationType.Sinful]}
              onShowInfo={() =>
                history.push(
                  PortfolioInfoRoute.format({
                    pensionInstitute,
                    insuranceShell,
                    recommendationType: RecommendationType.Sinful,
                  })
                )
              }
            />
          </TabPanel>
          <TabPanel padding={[0,2]}>
            <Portfolio
              recommendations={
                recommendations?.[RecommendationType.Sustainable]
              }
              onShowInfo={() =>
                history.push(
                  PortfolioInfoRoute.format({
                    pensionInstitute,
                    insuranceShell,
                    recommendationType: RecommendationType.Sustainable,
                  })
                )
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <HelpButton
        question={`Så byter du fonder på ${pensionInstitute}`}
        modalContent={
          <Text textStyle="p" mb={4}>
            Byta fonder hos {pensionInstitute} gör du enkelt på deras hemsida.
            Du loggar in med Bankid och väljer vilka fonder du vill byta till.{" "}
            <Link
              target="_blank"
              href={returnLinkValue(`${fundChangeLink}`)}
              rel="noreferrer"
              fontWeight="semibold"
              textDecoration="underline"
            >
              Logga in och byt fonder nu.
            </Link>{" "}
            <br />
            <br />
            Behöver du hjälp så kan du alltid kontakta vår kundtjänst.
          </Text>
        }
      />
    </Layout>
  );
};
