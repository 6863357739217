import { createIcon } from "@chakra-ui/react";

const BarChart = createIcon({
  displayName: "BarChart",
  viewBox: "0 0 21 16",
  path: (
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M19.478 14.609h-.695V0H16v14.609h-2.087V6.957H11.13v7.652H9.043V4.174H6.261v10.435H4.174V11.13H1.39v3.479H.696c-.385 0-.696.311-.696.695 0 .385.311.696.696.696h18.782c.384 0 .696-.311.696-.696 0-.384-.312-.695-.696-.695z"
              transform="translate(-113 -430) translate(113 428) translate(0 2)"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  defaultProps: {
    color: "denim.600",
  },
});

export default BarChart;
