import { createIcon } from "@chakra-ui/react";

const HelpPerson = createIcon({
  displayName: "HelpPerson",
  viewBox: "0 0 53 53",
  path: (
    <>
      <defs>
        <filter
          id="ev26osaqda"
          width="106.8%"
          height="143.8%"
          x="-3.4%"
          y="-21.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.501960784 0 0 0 0 0.580392157 0 0 0 0 0.631372549 0 0 0 0.25 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g
              filter="url(#ev26osaqda)"
              transform="translate(-272 -732) translate(33 734)"
            >
              <g transform="translate(223 3)">
                <circle cx="49.5" cy="15.5" r="10.5" fill="#3A5273" />
                <path
                  fill="#3A5273"
                  d="M57.303 34l5.087.67s2.374 18.419 1.357 19.759c-1.018 1.34-6.105 6.697-8.14 5.358C53.573 58.447 57.303 34 57.303 34z"
                />
                <path
                  fill="#A0616A"
                  d="M42.846 20s0 5.106-1.688 5.426c-1.688.319-5.064.319-6.077 1.914C34.07 28.936 42.846 50 42.846 50l9.116-5.426L55 30.213s-4.389-2.553-4.389-3.83v-5.745L42.846 20z"
                />
                <path
                  fill="#EFADAD"
                  d="M42.846 20s0 5.106-1.688 5.426c-1.688.319-5.064.319-6.077 1.914C34.07 28.936 42.846 50 42.846 50l9.116-5.426L55 30.213s-4.389-2.553-4.389-3.83v-5.745L42.846 20z"
                />
                <circle cx="48" cy="18" r="8" fill="#EAD8D8" />
                <path
                  fill="#3A5273"
                  d="M43.852 43.672S40.935 25 40.287 25c-.648 0-9.722 1.966-10.37 2.293C29.269 27.621 27 30.57 27 30.57l4.537 13.103s1.944 9.173 3.24 10.81c1.297 1.639 3.89 6.225 2.593 6.88-1.296.655-2.592 1.638-1.296 1.638s11.99-1.966 12.639-.983c.648.983 11.343-3.603 11.343-3.93 0-.328-1.297-15.725-1.297-15.725L62 34.828l-9.722-6.88-8.426 15.724z"
                />
                <path
                  fill="#3A5273"
                  d="M30.634 29l-3.38 1.627s-9.465 20.825-10.141 21.15c-.676.326-4.057 6.509-4.395 7.81-.338 1.302-1.014 5.532-2.028 6.183-.544.355-1.108.681-1.69.976 0 0 2.028 3.254 3.042 3.254 1.014 0 4.395-4.556 4.395-4.556s7.436 4.556 9.464.977C27.93 62.84 33 40.063 33 40.063L30.634 29z"
                />
                <path
                  fill="#EAD8D8"
                  d="M55 40.32s-4.02-11.57-6.317-10.209C46.386 31.472 50.406 39.64 52.99 41l2.01-.68z"
                />
                <path
                  fill="#000"
                  d="M50.821 39.029L55.513 38l.993 4.921s4.347 7.461 3.344 10.705c-1.003 3.244-7.022 8.434-9.029 5.514-2.006-2.92-3.678-7.46-2.34-10.055 1.337-2.595 3.344-8.759 2.34-10.056z"
                  opacity=".1"
                />
                <path
                  fill="#3A5273"
                  d="M51.821 39.029L56.513 38l.993 4.921s4.347 7.461 3.344 10.705c-1.003 3.244-7.022 8.434-9.029 5.514-2.006-2.92-3.678-7.46-2.34-10.055 1.337-2.595 3.344-8.759 2.34-10.056z"
                />
                <ellipse
                  cx="50.106"
                  cy="12.917"
                  fill="#3A5273"
                  rx="3.763"
                  ry="7.869"
                  transform="rotate(-64.899 50.106 12.917)"
                />
                <ellipse cx="55" cy="6.5" fill="#3A5273" rx="5" ry="4.5" />
                <path
                  fill="#3A5273"
                  d="M51.168 4.5C51.168 2.202 53.25.273 56 .027c-2.062-.188-4.072.622-5.174 2.083-1.101 1.462-1.101 3.318 0 4.78C51.928 8.35 53.938 9.16 56 8.973c-2.749-.246-4.832-2.175-4.832-4.473z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </>
  ),
  defaultProps: {
    color: "grey.400",
  },
});

export default HelpPerson;
