import { Skeleton, Text, Stack, Link } from "@chakra-ui/react";
import { Layout, ListItem, HelpButton } from "components/shared";
import { useInsuranceShellsByInstitute } from "hooks/funds";
import { HeapPagesEvents, trackHeapEvent } from "lib/analytics";
import {
  InsuranceShellsRouteParams,
  PensionInstitutesRoute,
  ShellRecommendationsRoute,
} from "lib/routes";
import { RecommendationType } from "models/funds";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

export const InsuranceShells = () => {
  const { pensionInstitute } = useParams<InsuranceShellsRouteParams>();
  const { isLoading, shells } = useInsuranceShellsByInstitute(pensionInstitute);
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && !shells.length) {
      history.push(PensionInstitutesRoute);
    }
  }, [isLoading, shells, history]);

  useEffect(() => {
    trackHeapEvent(HeapPagesEvents.ShowInsuranceShellsPage, {
      pensionInstitute,
    });
  }, []);

  return (
    <Layout title="Avtal" onGoBack={() => history.push(PensionInstitutesRoute)}>
      <Text textStyle="p" textAlign="center" mb={[10]}>
        Välj vilket försäkringsavtal du har.
      </Text>

      <Stack spacing={[3, 4]} mb={[20]}>
        {isLoading ? (
          <>
            <Skeleton height={[12]} />
            <Skeleton height={[12]} />
            <Skeleton height={[12]} />
            <Skeleton height={[12]} />
            <Skeleton height={[12]} />
          </>
        ) : (
          shells.map((shell) => (
            <ListItem
              key={shell._id}
              label={shell.insurance_type}
              onClick={() =>
                history.push(
                  ShellRecommendationsRoute.format({
                    pensionInstitute,
                    insuranceShell: shell.insurance_type,
                    recommendationType: RecommendationType.Sinful,
                  })
                )
              }
            />
          ))
        )}
      </Stack>
      <HelpButton
        question="Var ser jag vilket försäkringsavtal jag ska välja?"
        modalContent={
          <Text textStyle="p" mb={4}>
            Det gör du lättast genom att{" "}
            <Link
              target="_blank"
              href="https://www.minpension.se/"
              rel="noreferrer"
              fontWeight="semibold"
              textDecoration="underline"
            >
              logga in på MinPension.
            </Link>{" "}
            I sammanställningen över din pension du får där kan du se vilka
            pensionsbolag du har. Under varje pensionsbolag hittar du också
            vilket försäkringsavtal du har.
            <br />
            <br />
            Hittar du inte all information där kan du alltid logga in direkt hos
            pensionsbolagets hemsida. Där hittar du mer detaljer om ditt
            försäkringsavtal.
          </Text>
        }
      />
    </Layout>
  );
};
