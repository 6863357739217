import React from "react";
import { Box } from "@chakra-ui/react";
import { useWindowHeight } from "hooks/layout";
import { Pensa } from "assets/icons";

interface LayoutWithBgProps {
  children?: React.ReactNode;
}

export const LayoutWithBg = ({ children }: LayoutWithBgProps) => {
  const windowHeight = useWindowHeight();

  return (
    <>
      <Box
        minHeight={windowHeight}
        px={[6]}
        paddingTop={[10]}
        paddingBottom={[8, 20]}
        d="flex"
        flexDir="column"
        alignItems="stretch"
        mx="auto"
        maxWidth={["100%", "600px"]}
      >
        {children}
      </Box>
      <Pensa
        boxSize={[210, 350]}
        opacity="0.06"
        position="fixed"
        bottom="0"
        right="0"
        zIndex="-1"
        transform="translate(10%, 10%)"
      ></Pensa>
    </>
  );
};
