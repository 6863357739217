import textStyles from "../foundations/textStyles";

const label = {
  baseStyle: {
    ...textStyles.label,
    marginBottom: "0.25rem",

    _invalid: {
      opacity: 1,
      fontWeight: "semibold",
      color: "red.500",
    },

    _focus: {
      opacity: 1,
      fontWeight: "semibold",
    },
  },
};

export default label;
