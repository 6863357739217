import { AxaInsurances, AxaInsurancesUpsell, AxaProducts } from "api/endpoints";
import {
  AxaGroupInsurance,
  AxaInsuranceRequest,
  AxaProduct,
  InsuranceType,
} from "models/axa-products";
import { useCallback } from "react";
import { useApiSWR, useMutationRequest } from "./api";
import { useCurrentUser } from "./user";

export const useAxaProducts = () => {
  const { isLoading: isLoadingUser, user } = useCurrentUser();
  const { data, error } = useApiSWR<AxaProduct[]>(
    user ? AxaProducts(user.person_number) : undefined
  );

  return {
    isLoading: isLoadingUser || (!data && !error),
    products: data,
  };
};

export const useAxaInsurances = () => {
  const { isLoading: isLoadingUser, user } = useCurrentUser();
  const { data, error } = useApiSWR<AxaGroupInsurance[]>(
    user ? `${AxaInsurances}?userId=${user._id}` : undefined
  );
  const { isLoading: isLoadingProducts, products } = useAxaProducts();

  return {
    isLoading: isLoadingUser || isLoadingProducts || (!data && !error),
    insurances: {
      [InsuranceType.Safety]: data?.find(
        ({ product_code, end_date }) =>
          products?.find((product) => product.id === product_code)?.type ===
            InsuranceType.Safety && !end_date
      ),
      [InsuranceType.Life]: data?.find(
        ({ product_code, end_date }) =>
          products?.find((product) => product.id === product_code)?.type ===
            InsuranceType.Life && !end_date
      ),
    },
  };
};

export const usePurchaseAxaInsuranceRequest = () => {
  const { isLoading, request } = useMutationRequest<
    AxaInsuranceRequest,
    AxaGroupInsurance
  >(AxaInsurances);
  const { user } = useCurrentUser();
  const purchaseAxaInsurance = useCallback(
    (body: Omit<AxaInsuranceRequest, "user">) => {
      if (!user) {
        return;
      }
      request({ ...body, user: user._id });
    },
    [request, user]
  );

  return {
    purchaseAxaInsurance,
    isLoading,
  };
};

export const useUpsellAxaInsuranceRequest = () => {
  const { isLoading, request } = useMutationRequest<
    AxaInsuranceRequest,
    AxaGroupInsurance
  >(AxaInsurancesUpsell);
  const { user } = useCurrentUser();
  const upsellAxaInsurance = useCallback(
    (body: Omit<AxaInsuranceRequest, "user">) => {
      if (!user) {
        return;
      }
      request({ ...body, user: user._id });
    },
    [request, user]
  );

  return {
    upsellAxaInsurance,
    isLoading,
  };
};
