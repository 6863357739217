import { useMemo, useRef, useState, useEffect, useCallback } from "react";
import {
  Skeleton,
  Text,
  Box,
  useDisclosure,
  Collapse,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import {} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
// Components
import { RecommendationType } from "models/funds";
import { Card, RecommendedFunds } from "../shared";
// hooks
import {
  useInsuranceShells,
  useShellRecommendations,
  useShellRecommendationsPerfomrance,
} from "hooks/funds";
// other
import { formatSignedValue, getPerformanceDates } from "lib/utils";
import * as Icons from "assets/icons";
import "animate.css";
import { useShellRecommendationsData } from "hooks/pages/shellRecommendations";

interface PortfolioPerformanceProps {
  shellId: string;
  type: RecommendationType;
  isFavouritesPage?: boolean;
  removeItem?: (idx: number) => void;
  update?: (value: boolean) => void;
  index?: number;
  updateItem?: (callback: () => void) => void;
  toggleFavourite?: (
    selectedShell: string,
    recommendationType: RecommendationType,
    isCancel: boolean
  ) => void;
}

const portfolioTypeLabel = {
  [RecommendationType.Sinful]: "Standardportfölj",
  [RecommendationType.Sustainable]: "Hållbar portfölj",
};

export const EachPortfolioPerformance = ({
  shellId,
  type,
}: PortfolioPerformanceProps) => {
  const { isLoading: isLoadingShells, shells } = useInsuranceShells();
  const { isLoading: isLoadingRecommendations, recommendations } =
    useShellRecommendations(shellId);
  const { isLoading: isLoadingPerformance, performance } =
    useShellRecommendationsPerfomrance(shellId);

  const shell = useMemo(
    () => shells?.find(({ _id }) => _id === shellId),
    [shells, shellId]
  );
  const recsOfType = useMemo(
    () =>
      recommendations?.find(({ classification }) => classification === type),
    [recommendations, type]
  );
  const performanceOfType = useMemo(
    () => performance?.[type],
    [type, performance]
  );
  const { month, year } = useMemo(() => getPerformanceDates(), []);

  const { isOpen, onToggle } = useDisclosure();

  const {
    isLoading: isLoadingSRD,
    isSavingFavorites,
    recommendations: recommendationsSRD,
    isFavorite,
    fundChangeLink,
    onToggleFavorite,
  } = useShellRecommendationsData(
    String(shell?.pension_institute),
    String(shell?.insurance_type),
    type
  );
  const isLoading =
    isLoadingRecommendations ||
    isLoadingShells ||
    isLoadingPerformance ||
    isLoadingSRD;

  // redirect from this route if PPM selected and no consent given

  // redirect from this route if we don't have recommendations data

  const onFavoriteClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (isSavingFavorites) {
        return;
      }

      onToggleFavorite();
    },
    [isSavingFavorites, isFavorite, onToggleFavorite]
  );

  if (isLoading) {
    return <Skeleton height={["9.5rem", "10.25rem"]} isLoaded={false} />;
  }

  return (
    <Card
      px={[7, 10]}
      py={[4, 6]}
      color="denim.600"
      cursor="pointer"
      onClick={onToggle}
      style={{ position: "relative" }}
    >
      <Text
        textStyle="p"
        fontWeight="bold"
        letterSpacing="0.5px"
        as="h6"
        mb={1}
      >
        {shell?.pension_institute}
      </Text>

      <Box
        mb={4}
        d="flex"
        flexDir={["column", "column", "row"]}
        justifyContent="space-between"
      >
        <Text textStyle="p">{shell?.insurance_type}</Text>
        {performanceOfType && (
          <Box alignSelf="flex-end">
            <HStack spacing={3}>
              <PerformanceItem
                label={month}
                meanReturn={performanceOfType.total.month}
              />
              <PerformanceItem
                label={year}
                meanReturn={performanceOfType.total.year}
              />
              <div style={{ position: "absolute", top: "16px", right: "16px" }}>
                <Box
                  onClick={
                    !isLoading
                      ? (event) => {
                          onFavoriteClick(event);
                        }
                      : () => {}
                  }
                >
                  <>
                    {!isLoading && (
                      <>
                        {isSavingFavorites || isLoading ? (
                          <Spinner color="#000" speed="0.5s" size="sm" />
                        ) : (
                          <>
                            {isFavorite ? (
                              <Icons.Heart w={["18px"]} h={["16px"]} />
                            ) : !isSavingFavorites || !isLoading ? (
                              <Icons.HeartOutlined w={["18px"]} h={["16px"]} />
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </>
                </Box>
              </div>
            </HStack>
          </Box>
        )}
      </Box>
      <Box d="flex" alignItems="center" justifyContent="space-between">
        <Text textStyle="p" fontSize={["md", "xl"]} color="grey.400">
          {portfolioTypeLabel[type]}
        </Text>
        <ChevronDownIcon
          boxSize={5}
          transform={!isOpen ? "rotate(180deg)" : "rotate(0)"}
        />
      </Box>

      <Collapse in={!isOpen} animateOpacity>
        <Box mt={[4]}>
          {recsOfType && <RecommendedFunds recommendations={recsOfType} />}
        </Box>
      </Collapse>
    </Card>
  );
};

interface PerformanceItemProps {
  label: string;
  meanReturn: number;
}

const PerformanceItem = ({ label, meanReturn }: PerformanceItemProps) => (
  <Box>
    <Text textStyle="p" textAlign="right" textTransform="capitalize">
      {label}
    </Text>
    <Box d="flex" alignItems="center">
      {meanReturn > 0 && <Icons.ArrowUp w={21} h={21} marginRight={2} />}
      {meanReturn === 0 && <Icons.ArrowUp w={21} h={21} marginRight={2} />}
      {meanReturn < 0 && <Icons.ArrowDown w={21} h={21} marginRight={2} />}
      <Text textStyle="h2">{formatSignedValue(meanReturn, 1)}%</Text>
    </Box>
  </Box>
);
